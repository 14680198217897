import commonHelper from '@/app/utility/common.helper.utility';
import gstrThreeBScr2 from './gstrThreeBScr2';
export default {
  name: 'gstrThreeB',
  components: { gstrThreeBScr2 },
  watch: {
    searchParams() {
      if (this.searchParams) {
        this.getGstrThreeList(this.searchParams);
      } else {
        this.gstrTwoData = [];
      }
    },
    totalRows() {
      this.$emit('totalRowsCount', this.totalRows);
    },
    currentPage: function() {
      this.searchParams._page = this.currentPage - 1;
      this.getGstrThreeList(this.searchParams);
    }
  },
  props: ['searchParams', 'showCharts'],
  data() {
    return {
      loading: false,
      currentPage: 1,
      perPage: commonHelper.perPageRecord,
      totalRows: null,
      gstrThreebData: [],
      gstrThreebFields: [
        {
          key: 'selectBox',
          label: 'Select',
          stickyColumn: true,
          variant: 'info',
          class: 'stickyColumn col-sm-1'
        },
        {
          key: 'fp_gstn',
          class: 'col-fix'
        },
        {
          key: 'reconciled_amount',
          label: 'Reco Amount',
          class: 'col-fix'
        },
        {
          key: 'igst',
          label: 'IGST',
          variant: 'success',
          class: 'text-center d-total-line'
        },

        {
          key: 'cgst',
          label: 'CGST',
          variant: 'success',
          class: 'text-center d-total-line'
        },
        {
          key: 'sgst',
          label: 'SGST',
          variant: 'success',
          class: 'text-center d-total-line'
        },
        {
          key: 'auto_recon',
          class: 'col-fix'
        },
        {
          key: 'correction_recon',
          class: 'col-fix'
        },
        {
          key: 'manual_reco',
          label: 'Manual Recon',
          class: 'col-fix'
        }
      ],
      recordList: [],
      recordFields: [
        {
          key: 'tax_gl_prd',
          label: 'GL Period',
          class: 'col-fix'
        },
        {
          key: 'reconciled_amount',
          label: 'Reco Amount',
          class: 'col-fix'
        },
        {
          key: 'igst',
          label: 'IGST',
          variant: 'success',
          class: 'text-center d-total-line'
        },

        {
          key: 'cgst',
          label: 'CGST',
          variant: 'success',
          class: 'text-center d-total-line'
        },
        {
          key: 'sgst',
          label: 'SGST',
          variant: 'success',
          class: 'text-center d-total-line'
        },
        {
          key: 'auto_recon',
          class: 'col-fix'
        },
        {
          key: 'correction_recon',
          class: 'col-fix'
        },
        {
          key: 'manual_recon',
          class: 'col-fix'
        }
      ],
      showOpenGstr3BScr2Modal: false,
      minimizeFlag: true,
      rowDetails: null,
      rowPeriod: null,
      rowLeId: null,
      totalRecoAmt: 0,
      totalCorrRecon: 0,
      totalAutoRecon: 0,
      totalManualRecon: 0,
      selectAllCheckBox: false,
      selectedRow: [],
      accessButtons: {
        deleteAllow: false,
        downloadAllow: true,
        uploadAllow: false,
        editAllow: false,
        addAllow: false,
        printAllow: false,
        approvalAllow: false
      }
    };
  },
  mounted() {
    if (this.searchParams.selectedPeriod) {
      this.getGstrThreeList();
    }
  },
  methods: {
    getRecordList(data) {
      data.toggleDetails();
      const payload = {
        period: this.searchParams.period,
        le_id: this.searchParams.le_id,
        fp_gstn: data.value
      };
      this.loading = true;
      this.$store
        .dispatch('complainceDashboard/complianceTab3scr3', payload)
        .then(response => {
          if (response.status === 200) {
            this.recordList = response.data.data;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    formattedNumber(num) {
      return commonHelper.formatCurrency(num);
    },
    rowSelected(item) {
      this.showOpenGstr3BScr2Modal = true;
      this.rowDetails = item;
      this.$store.commit('complainceDashboard/setscr2ModalFlag', true);
    },
    showHideGstr3BScr2Modal(flag) {
      this.showOpenGstr3BScr2Modal = flag;
      this.$store.commit('complainceDashboard/setscr2ModalFlag', false);
    },
    clearLoadingTimeInterval() {
      clearInterval(this.$_loadingTimeInterval);
      this.$_loadingTimeInterval = null;
    },
    totalLines() {
      this.showPieChartModal = true;
      this.chart = 'SHOW CHARTS';
      let unrecLines = 0;
      let recLines = 0;
      let rectax = 0;
      let unrectax = 0;
      let gstn = [];
      let reco = [];
      for (let i of this.selectedRow) {
        unrecLines = unrecLines + i.unrec_lines;
        recLines = recLines + i.rec_lines;
        rectax = rectax + i.rec_amount;
        unrectax = unrectax + i.unrec_amount;
        gstn.push(i.first_party_gstn);
        reco.push(Number(i.total_rec_amount_prcnt));
      }

      if (unrecLines === 0 && recLines === 0) {
        this.showHistoryBtn = false;
      }
      this.chartData = [
        ['Reconciled Lines', recLines],
        ['Unreconciled Lines', unrecLines]
      ];
      this.chartData1 = [
        ['Total Reconciled', rectax],
        ['Total Unreconciled', unrectax]
      ];
      this.cate = gstn;
      this.chartData2 = reco;
    },
    toggleModal() {
      this.$refs['my-modal'].toggle('#toggle-btn');
    },
    getGstrThreeList(params) {
      let payload = this.searchParams;
      if (params) {
        payload = Object.assign(payload, params);
      }
      this.loading = true;
      this.totalRecoAmt = 0;
      this.totalCorrRecon = 0;
      this.totalAutoRecon = 0;
      this.totalManualRecon = 0;
      this.$store.commit('complainceDashboard/setLoading', this.loading);
      this.$store
        .dispatch('complainceDashboard/complianceTab3', payload)
        .then(response => {
          if (response.status === 200) {
            this.gstrThreebData = response.data.data.page;
            for (let i of this.gstrThreebData) {
              this.totalRecoAmt = this.totalRecoAmt + i.reconciled_amount;
              this.totalCorrRecon = this.totalCorrRecon + i.correction_recon;
              this.totalAutoRecon = this.totalAutoRecon + i.auto_recon;
              this.totalManualRecon = this.totalManualRecon + i.manual_reco;
            }
            this.totalRows = response.data.data.total_elements;
            this.loading = false;
            this.$store.commit('complainceDashboard/setLoading', this.loading);
            this.rowPeriod = this.searchParams.period;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    maximize() {
      this.minimizeFlag = !this.minimizeFlag;
    },
    expandColumn(data, class1, class2) {
      let elem = document.querySelectorAll(`.${class1}`);
      if (elem && elem[0] && elem[0].classList.contains(class1)) {
        if (data === 'reconciled_amount') {
          elem.forEach(el => {
            el.classList.replace('d-total-line', class2);
          });
        }
      } else {
        if (data === 'reconciled_amount') {
          let otherElem = document.querySelectorAll('.other-total-line');
          otherElem.forEach(el => {
            el.classList.replace('other-total-line', 'd-total-line');
          });
        }
      }
    },
    selectAllBoxChecked(flag) {
      this.selectedRow = [];
      if (flag) {
        this.gstrThreebData = this.gstrThreebData.map((data, index) => {
          data.selectBox = this.selectAllCheckBox;
          if (this.selectAllCheckBox) {
            this.selectBoxChecked(data.selectBox, index);
          }
          return data;
        });
        this.selectedRow = this.gstrThreebData;
        for (let i of this.selectedRow) {
          this.totalRecoAmt = this.totalRecoAmt + i.reconciled_amount;
          this.totalAutoRecon = this.totalAutoRecon + i.auto_recon;
          this.totalCorrRecon = this.totalCorrRecon + i.correction_recon;
          this.totalManualRecon = this.totalManualRecon + i.manual_reco;
        }
      } else {
        this.gstrThreebData = this.gstrThreebData.map((data, index) => {
          data.selectBox = this.selectAllCheckBox;
          if (this.selectAllCheckBox) {
            this.selectBoxChecked(data.selectBox, index);
          }
          return data;
        });
        this.selectedRow = [];
        this.totalRecoAmt = 0;
        this.totalCorrRecon = 0;
        this.totalAutoRecon = 0;
        this.totalManualRecon = 0;
        for (let i of this.gstrThreebData) {
          this.totalRecoAmt = this.totalRecoAmt + i.reconciled_amount;
          this.totalAutoRecon = this.totalAutoRecon + i.auto_recon;
          this.totalCorrRecon = this.totalCorrRecon + i.correction_recon;
          this.totalManualRecon = this.totalManualRecon + i.manual_reco;
        }
      }
    },
    selectBoxChecked(flag, index, item, reconciledAmount) {
      this.totalRecoAmt = 0;
      this.totalCorrRecon = 0;
      this.totalAutoRecon = 0;
      this.totalManualRecon = 0;
      this.gstrThreebData[index].selectBox = flag;
      if (flag === true) {
        this.selectedRow.push(item);
      } else {
        this.selectedRow = this.selectedRow.filter(
          el => el.reconciled_amount !== reconciledAmount
        );
      }
    },
    showTotal() {
      let totRecAmt = 0;
      let totAutoReco = 0;
      let totCorrReco = 0;
      let totManReco = 0;
      for (let i of this.selectedRow) {
        totRecAmt = totRecAmt + i.reconciled_amount;
        totAutoReco = totAutoReco + i.auto_recon;
        totCorrReco = totCorrReco + i.correction_recon;
        totManReco = totManReco + i.manual_reco;
      }
      this.totalRecoAmt = totRecAmt;
      this.totalCorrRecon = totCorrReco;
      this.totalAutoRecon = totAutoReco;
      this.totalManualRecon = totManReco;
    }
  }
};
