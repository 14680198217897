import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import store from '../../../../../../store';
import { BasicSelect, ModelSelect } from 'vue-search-select';
import { required } from 'vuelidate/lib/validators';
export default {
  name: '',
  watch: {
    currentPage: function() {
      this.getItcSummaryReport();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getItcSummaryReport();
    }
  },
  components: {
    BasicSelect,
    ModelSelect
  },
  props: ['searchFilters'],
  data() {
    return {
      payload: null,
      unsubscribe: null,
      totalRows: null,
      currentPage: 1,
      showValueSetModal: false,
      setTimeVsetCode: null,
      valueSetName: null,
      parent_value_set_id: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      loader: false,
      data: [],
      selectedLegalEntity: {
        value: null,
        text: null
      },
      selectedITCType: {
        value: '2B',
        text: '2B'
      },
      selectedGSTNumber: {
        value: null,
        text: null
      },
      selectedPeriodFrom: {
        value: null,
        text: null
      },
      selectedPeriodTo: {
        value: null,
        text: null
      },
      periodType: null,
      itcSummaryReportdata: [],
      GSTNumberTypeList: [],
      legalEntityList: [],
      PeriodToTypeList: [],
      PeriodFromTypeList: [],
      downloadPayload: null,
      ITCTypeList: [
        {
          value: '2A',
          text: '2A'
        },
        {
          value: '2B',
          text: '2B'
        },
        {
          value: '6A',
          text: '6A'
        },

      ],
      fields: [
        {
          key: 'itc_type',
          label: 'ITC Type',
          class: 'text-center col-fix'
        },
        {
          key: 'gstn_no',
          label: 'GSTN Number',
          class: 'text-center col-fix'
        },
        {
          key: 'igst_amount',
          label: 'IGST',
          stickyColumn: true,
          class: 'text-center col-fix',
          variant: 'info'
        },
        {
          key: 'cgst_amount',
          label: 'CGST',
          stickyColumn: true,
          class: 'text-center col-fix',
          variant: 'info'
        },
        {
          key: 'sgst_amount',
          label: 'SGST',
          stickyColumn: true,
          class: 'text-center col-fix',
          variant: 'info'
        },
        {
          key: 'b2b',
          label: 'B2B',
          stickyColumn: true,
          class: 'text-center col-fix',
          variant: 'warning'
        },
        {
          key: 'b2ba',
          label: 'B2BA',
          stickyColumn: true,
          class: 'text-center col-fix',
          variant: 'warning'
        },
        {
          key: 'cdnr',
          label: 'CDNR',
          stickyColumn: true,
          class: 'text-center col-fix',
          variant: 'primary'
        },
        {
          key: 'cdnra',
          label: 'CDNRA',
          stickyColumn: true,
          class: 'text-center col-fix',
          variant: 'primary'
        },
        {
          key: 'isd',
          label: 'ISD',
          stickyColumn: true,
          class: 'text-center col-fix',
          variant: 'secondary'
        },
        {
          key: 'isda',
          label: 'ISDA',
          stickyColumn: true,
          class: 'text-center col-fix',
          variant: 'secondary'
        },
        {
          key: 'impg',
          label: 'IMPG',
          stickyColumn: true,
          class: 'text-center col-fix',
          variant: 'success'
        },
        {
          key: 'impgsez',
          label: 'IMPGSEZ',
          stickyColumn: true,
          class: 'text-center col-fix',
          variant: 'success'
        }
      ],
      recordList: [],
      recordFields: [
        {
          key: 'itc_type',
          label: 'ITC Type',
          class: 'text-center col-fix'
        },
        {
          key: 'period_name',
          label: 'Period Name',
          class: 'text-center col-fix'
        },
        {
          key: 'igst_amount',
          label: 'IGST',
          stickyColumn: true,
          class: 'text-center col-fix'
        },
        {
          key: 'Cgst_amount',
          label: 'CGST',
          stickyColumn: true,
          class: 'text-center col-fix'
        },
        {
          key: 'Sgst_amount',
          label: 'SGST',
          stickyColumn: true,
          class: 'text-center col-fix'
        },
        {
          key: 'b2b',
          label: 'B2B',
          stickyColumn: true,
          class: 'text-center col-fix'
        },
        {
          key: 'b2ba',
          label: 'B2BA',
          stickyColumn: true,
          class: 'text-center col-fix'
        },
        {
          key: 'cdnr',
          label: 'CDNR',
          stickyColumn: true,
          class: 'text-center col-fix'
        },
        {
          key: 'cdnra',
          label: 'CDNRA',
          stickyColumn: true,
          class: 'text-center col-fix'
        },
        {
          key: 'isd',
          label: 'ISD',
          stickyColumn: true,
          class: 'text-center col-fix'
        },
        {
          key: 'isda',
          label: 'ISDA',
          stickyColumn: true,
          class: 'text-center col-fix'
        },
        {
          key: 'impg',
          label: 'IMPG',
          stickyColumn: true,
          class: 'text-center col-fix'
        },
        {
          key: 'impgsez',
          label: 'IMPGSEZ',
          stickyColumn: true,
          class: 'text-center col-fix'
        }
      ],
      showSecondTable: false
    };
  },
  validations: {
    selectedLegalEntity: {
      text: {
        required
      }
    },
    selectedITCType: {
      text: {
        required
      }
    },
    selectedPeriodFrom: {
      text: {
        required
      }
    },
    selectedPeriodTo: {
      text: {
        required
      }
    }
  },
  mounted() {
    if(this.searchFilters) {
      this.selectedLegalEntity = this.searchFilters.leName,
      this.selectedPeriodFrom = this.searchFilters.selectedPeriodFrom,
      this.selectedPeriodTo = this.searchFilters.selectedPeriodTo,
      this.selectedGSTNumber = this.searchFilters.selectedGSTNumber
    }
    this.getLegalEntity();
    this.getItcSummaryReport();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showChildModal = true;
        }
        if (actionName === 'download') {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'itcSummaryReport/getItcSummaryReport',
            'itc-summary-report',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    downloadExcelData() {
      this.loader = true;
      const downloadpayload = {...this.downloadPayload};
      downloadpayload._limit = this.totalRows;
      this.downloadExcel.downloadData(
        downloadpayload,
        'itcSummaryReport/getItcSummaryReport',
        'itc-summary-report',
      () => (this.loader = false)
      );
    },
    formattedNumber(num) {
      return commonHelper.formatCurrency(num);
    },
    getItcSummaryReport() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          le_id: this.selectedLegalEntity.value,
          gst_number: this.selectedGSTNumber.value,
          itc_type: this.selectedITCType.value,
          period_from: this.selectedPeriodFrom.value,
          period_to: this.selectedPeriodTo.value
        };
        this.loader = true;
        this.downloadPayload = this.payload;
        this.$store
          .dispatch('itcSummaryReport/getItcSummaryReport', this.payload)
          .then(resp => {
            this.loader = false;
            if (resp.status === 200) {
              this.itcSummaryReportdata = resp.data.data.page;
              this.totalRows = resp.data.data.total_elements;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    getLegalEntity() {
      const payload = store.state.shared.responsibilityId;
      this.$store
        .dispatch('receipt/getOrganizationLov', payload)
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data;
            const leagalEntity = results.map(type => {
              return {
                value: type.org_id,
                text: type.org_name
              };
            });
            this.legalEntityList = leagalEntity;
            this.selectedLegalEntity =
              leagalEntity && leagalEntity.length === 1
                ? leagalEntity[0]
                : this.selectedLegalEntity;
          }
        });
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.selectedLegalEntity = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      }
      else if (this.vsetCode === appStrings.VALUESETTYPE.GST_FIRST_PARTY_GSTN) {
        this.selectedGSTNumber = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      }
      else if (this.vsetCode === appStrings.VALUESETTYPE.GL_PERIOD) {
        if (this.periodType === 'periodFrom') {
          this.selectedPeriodFrom = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
        } else if (this.periodType === 'periodTo') {
          this.selectedPeriodTo = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
        }
      }
      this.showValueSetModal = false;
    },
    openValueSetModal(vsetCode, periodType) {
      this.showValueSetModal = true;
      this.vsetCode = vsetCode;
      this.periodType = periodType;
      if (this.vsetCode === appStrings.VALUESETTYPE.GST_FIRST_PARTY_GSTN || this.vsetCode === appStrings.VALUESETTYPE.GL_PERIOD) {
        this.parent_value_set_id = this.selectedLegalEntity.value;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clear() {
      this.selectedLegalEntity = {
        value: null,
        text: null
      };
      this.selectedITCType = {
        value: null,
        text: null
      };
      this.selectedGSTNumber = {
        value: null,
        text: null
      };
      this.selectedPeriodFrom = {
        value: null,
        text: null
      };
      this.selectedPeriodTo = {
        value: null,
        text: null
      };
      this.itcSummaryReportdata = [];
      this.totalRows = null;
      this.currentPage = 1;
    },
    rowSelected() { },
    clearVsetValues(vsetCode,periodType){
      if (vsetCode === this.selectedLegalEntity.value) {
        this.selectedLegalEntity = {
          value: null,
          text: null
        };
      }
      else if (vsetCode === 'GST_FIRST_PARTY_GSTN') {
        this.selectedGSTNumber = {
          value: null,
          text: null
        };
      }
      else if (vsetCode === 'GL_PERIOD') {
        if (periodType === 'periodFrom') {
          this.selectedPeriodFrom = {
            value: null,
            text: null
          };
        } else if (periodType === 'periodTo') {
          this.selectedPeriodTo = {
            value: null,
            text: null
          };
        }
      }
    },
    getRecordList(data,item) {
      data.toggleDetails();
      this.payload = {
        _page: this.currentPage - 1,
        _limit: 100,
        le_id: this.selectedLegalEntity.value,
        gstn_no: item.gstn_no,
        itc_type: this.selectedITCType.value,
        period_from: this.selectedPeriodFrom.value,
        period_to: this.selectedPeriodTo.value
      };
      this.loader = true;
      this.downloadPayload = this.payload;
      this.$store
        .dispatch('itcSummaryReport/getItsSummaryRecords', this.payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.recordList = resp.data.data.page;
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        }); 
    },
  },
  beforeDestroy() {
    clearTimeout(this.setTimeVsetCode);
    this.unsubscribe();
  }
};
