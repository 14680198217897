import appStrings from '@/app/utility/string.utility';
import commonHelper from '@/app/utility/common.helper.utility';
import gstrDetailsNearMatch from './gstrDetailsNearMatch';
export default {
  name: 'gstrTwoDetails',
  props: ['rowGstrDetails', 'searchGstrFilters'],
  components: {
    gstrDetailsNearMatch
  },
  watch: {
    currentPage: function() {
      this.getGstrDetails() ? this.getGstrDetails() : this.getFlagDetails();
    },
    perPage: function() {
      this.rowGstrDetails.vendor_name = this.vendorName;
      this.rowGstrDetails.vendor_pan = this.vendorPan;
      this.currentPage = 1;
      this.getGstrDetails() ? this.getGstrDetails() : this.getFlagDetails();
    }
  },
  data() {
    return {
      minimizeFlag: true,
      selectAllCheckBox: false,
      showHistoryBtn: false,
      taxableAmount: 0,
      totalUnreconciled: 0,
      selectedRow: [],
      loading: false,
      currentPage: 1,
      totalRows: null,
      perPage: commonHelper.perPageData,
      pageOptions: commonHelper.getPageOptionsList(),
      defaultValue: {
        value: null,
        text: null
      },
      leName: {
        value: null,
        text: null
      },
      selectedGSTNumber: null,
      vendorPan: null,
      vendorName: null,
      tpGstn: null,
      invNum: null,
      selectedPeriodFrom: {
        value: null,
        text: null
      },
      selectedPeriodTo: {
        value: null,
        text: null
      },
      revGst: {
        value: null,
        text: null
      },
      recoGst: {
        value: null,
        text: null
      },
      recoStatus: {
        value: '0',
        text: 'UnReconciled'
      },
      selectedNearMatch: {
        value: 'N',
        text: 'No'
      },
      gstrDetailsData: [],
      gstrDetailsFields: [
        {
          key: 'selectBox',
          label: 'Select',
          stickyColumn: true,
          variant: 'info',
          class: 'stickyColumn col-sm-1'
        },
        {
          key: 'fp_gstn',
          label: 'FP GSTN',
          variant: 'success',
          class: 'col-fix'
        },
        {
          key: 'vendor_name'
        },
        {
          key: 'vendor_pan',
          label: 'Vendor PAN',
          class: 'text-center col-fix'
        },
        {
          key: 'gl_date',
          label: 'GL Date',
          class: 'gldate col-fix'
        },
        {
          key: 'invoice_num',
          class: 'col-fix'
        },
        {
          key: 'rate',
          class: 'rate col-fix'
        },
        {
          key: 'vendor_gstn',
          label: 'Vendor GSTN',
          class: 'col-fix'
        },
        {
          key: 'taxable_amt',
          class: 'text-center col-fix'
        },
        {
          key: 'total_unrec_tax',
          label: 'Unreco Tax',
          class: 'text-center col-fix'
        },
        {
          key: 'igst',
          label: 'IGST',
          class: 'd-total-unre gst text-center col-fix',
          variant: 'success'
        },
        {
          key: 'csgst',
          label: 'CGST',
          class: 'd-total-unre gst text-center col-fix',
          variant: 'success'
        },
        {
          key: 'sgst',
          label: 'SGST',
          class: 'd-total-unre gst text-center col-fix',
          variant: 'success'
        },
        {
          key: 'total_rec_tax',
          label: 'Reco Tax',
          class: 'text-center col-fix'
        }
      ],
      showValueSetModal: false,
      periodType: null,
      showGstrNearMatch: false,
      rowDetails: null,
      leId: null,
      searchFilters: {
        leName: {
          value: null,
          text: null
        },
        period_from: {
          value: null,
          text: null
        },
        period_to: {
          value: null,
          text: null
        },
        gst_number: null,
        invoice_num: null,
        downloadPayload: null
      },
      inv_num: null
    };
  },
  mounted() {
    this.getGstrDetails();
    if (this.searchGstrFilters) {
      this.getParentToChildData(this.searchGstrFilters);
    }
  },
  methods: {
    downloadExcelData() {
      this.loader = true;
      const downloadpayload = {...this.downloadPayload};
      downloadpayload._limit = this.totalRows;
      if(this.selectedNearMatch.value) {
        this.downloadExcel.downloadData(
          downloadpayload,
          'complainceDashboard/getGstrDetailsFlag',
          'gstr-two-details-data',
        () => (this.loader = false)
        );
      } else {        
      this.downloadExcel.downloadData(
        downloadpayload,
        'complainceDashboard/getGstrDetails',
        'gstr-two-details-data',
      () => (this.loader = false)
      );
      }
    },
    formattedNumber(num) {
      return commonHelper.formatCurrency(num);
    },
    showHideGstrNearMatch(flag) {
      this.showGstrNearMatch = flag;
    },
    rowSelected(item) {
      this.rowDetails = item;
      this.leId = this.leName.value;
      this.showGstrNearMatch = true;
      this.searchFilters.leName=this.leName;
      this.searchFilters.period_from = this.selectedPeriodFrom;
      this.searchFilters.period_to = this.selectedPeriodTo;
      this.searchFilters.gst_number = this.selectedGSTNumber;
      this.searchFilters.invoice_num = this.invNum;
      this.inv_num = item.invoice_num;
    },
    selectAllBoxChecked(flag) {
      this.selectedRow = [];
      if (flag) {
        this.gstrDetailsData = this.gstrDetailsData.map((data, index) => {
          data.selectBox = this.selectAllCheckBox;
          if (this.selectAllCheckBox) {
            this.selectBoxChecked(data.selectBox, index);
          }
          return data;
        });
        this.selectedRow = this.gstrDetailsData;
        for (let i of this.selectedRow) {
          this.taxableAmount = this.taxableAmount + i.taxable_amt;
          this.totalUnreconciled = this.totalUnreconciled + i.total_unrec_tax;
        }
      } else {
        this.gstrDetailsData = this.gstrDetailsData.map((data, index) => {
          data.selectBox = this.selectAllCheckBox;
          if (this.selectAllCheckBox) {
            this.selectBoxChecked(data.selectBox, index);
          }
          return data;
        });
        this.selectedRow = [];
        this.taxableAmount = 0;
        this.totalReconciled = 0;
        this.totalUnreconciled = 0;
        for (let i of this.gstrDetailsData) {
          this.taxableAmount = this.taxableAmount + i.taxable_amt;
          this.totalUnreconciled = this.totalUnreconciled + i.total_unrec_tax;
        }
        this.showHistoryBtn = false;
      }
    },
    selectBoxChecked(flag, index, item, invoice) {
      this.taxableAmount = 0;
      this.totalUnreconciled = 0;
      this.gstrDetailsData[index].selectBox = flag;
      if (flag === true) {
        this.selectedRow.push(item);
      } else {
        this.selectedRow = this.selectedRow.filter(
          el => el.invoice_num !== invoice
        );
      }
      if (this.selectedRow.length > 0) {
        this.showHistoryBtn = true;
      } else {
        this.showHistoryBtn = false;
      }
    },
    showTotal() {
      let taxValue = 0;
      let totUnrec = 0;
      for (let i of this.selectedRow) {
        taxValue = taxValue + i.taxable_amt;
        totUnrec = totUnrec + i.total_unrec_tax;
      }

      this.taxableAmount = taxValue;
      this.totalUnreconciled = totUnrec;
    },
    getParentToChildData(item) {
      this.leName.value = item.le_id;
      this.leName.text = item.leName;
      this.selectedPeriodFrom.text = item.period_start_date;
      this.selectedPeriodTo.text = item.period_end_date;
      this.selectedPeriodFrom.value = item.period_start_date;
      this.selectedPeriodTo.value = item.period_end_date;
      this.selectedGSTNumber = this.rowGstrDetails.fp_gstn;
      this.vendorPan = this.rowGstrDetails.vendor_pan;
      this.vendorName = this.rowGstrDetails.vendor_name;
      this.revGst = {
        value: item.reverse_charge,
        text: item.selectedReverseCharge
      }
    },
    getGstrDetails() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        le_id: this.searchGstrFilters.le_id,
        fp_gstn: this.rowGstrDetails.fp_gstn,
        period_from: this.searchGstrFilters.period_start_date,
        period_to: this.searchGstrFilters.period_end_date,
        vendor_pan: this.rowGstrDetails.vendor_pan
          ? this.rowGstrDetails.vendor_pan
          : null,
        vendor_name: this.rowGstrDetails.vendor_name
          ? this.rowGstrDetails.vendor_name
          : null,
        invoice_num: this.invoice,
        tp_gstn: this.tpGstn,
        rev_charge: this.revGst.value,
        recoverable_flag: this.recoGst.value,
        reco_flag_2b: this.recoStatus.value,
        flag: 'N'
      };
      this.downloadPayload = payload;
      this.loading = true;
      this.$store
        .dispatch('complainceDashboard/getGstrDetailsFlag', payload)
        .then(response => {
          this.loading = false;
          if (response.status === 200) {
            this.gstrDetailsData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
            this.taxableAmount = 0;
            this.totalUnreconciled = 0;
            for (let i of this.gstrDetailsData) {
              this.taxableAmount = this.taxableAmount + i.taxable_amt;
              this.totalUnreconciled =
                this.totalUnreconciled + i.total_unrec_tax;
            }
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getGstrTwoDetailsData() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        le_id: this.leName.value,
        fp_gstn: this.selectedGSTNumber,
        period_from: this.selectedPeriodFrom.value,
        period_to: this.selectedPeriodTo.value,
        vendor_pan: this.vendorPan ? this.vendorPan : null,
        vendor_name: this.vendorName ? this.vendorName : null,
        invoice_num: this.invNum,
        tp_gstn: this.tpGstn,
        rev_charge: this.revGst.value,
        recoverable_flag: this.recoGst.value,
        reco_flag_2b: this.recoStatus.value
      };
      this.loading = true;
      this.$store
        .dispatch('complainceDashboard/getGstrDetails', payload)
        .then(response => {
          this.loading = false;
          if (response.status === 200) {
            this.gstrDetailsData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
            this.taxableAmount = 0;
            this.totalUnreconciled = 0;
            for (let i of this.gstrDetailsData) {
              this.taxableAmount = this.taxableAmount + i.taxable_amt;
              this.totalUnreconciled =
                this.totalUnreconciled + i.total_unrec_tax;
            }
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getGstrDetailsFlag() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        le_id: this.leName.value,
        fp_gstn: this.selectedGSTNumber,
        period_from: this.selectedPeriodFrom.value,
        period_to: this.selectedPeriodTo.value,
        vendor_pan: this.vendorPan ? this.vendorPan : null,
        vendor_name: this.vendorName ? this.vendorName : null,
        invoice_num: this.invNum,
        tp_gstn: this.tpGstn,
        rev_charge: this.revGst.value,
        recoverable_flag: this.recoGst.value,
        reco_flag_2b: this.recoStatus.value,
        flag: this.selectedNearMatch.value
      };
      this.downloadPayload = payload;
      this.loading = true;
      this.taxableAmount = 0;
      this.totalUnreconciled = 0;
      this.$store
        .dispatch('complainceDashboard/getGstrDetailsFlag', payload)
        .then(response => {
          this.loading = false;
          if (response.status === 200) {
            this.gstrDetailsData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
            for (let i of this.gstrDetailsData) {
              this.taxableAmount = this.taxableAmount + i.taxable_amt;
              this.totalUnreconciled =
                this.totalUnreconciled + i.total_unrec_tax;
            }
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getFlagDetails() {
      if (this.selectedNearMatch.value) {
        this.getGstrDetailsFlag();
      } else {
        this.getGstrTwoDetailsData();
      }
    },
    expandColumn(data, class1, class2) {
      let elem = document.querySelectorAll(`.${class1}`);
      if (elem && elem[0] && elem[0].classList.contains(class1)) {
        if (data === 'total_unrec_tax') {
          elem.forEach(el => {
            el.classList.replace('d-total-unre', class2);
          });
        }
      } else {
        if (data === 'total_unrec_tax') {
          let otherElem = document.querySelectorAll('.other-total-rectax');
          otherElem.forEach(el => {
            el.classList.replace('other-total-rectax', 'd-total-unre');
          });
        }
      }
    },
    openValueSetModal(vsetCode, periodType) {
      this.showValueSetModal = true;
      this.vsetCode = vsetCode;
      this.periodType = periodType;
      this.parent_value_set_id = null;
      if (
        this.vsetCode === appStrings.VALUESETTYPE.GST_FIRST_PARTY_GSTN ||
        this.vsetCode === appStrings.VALUESETTYPE.GL_PERIOD
      ) {
        this.parent_value_set_id = this.leName.value;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.leName = {
          value: item.org_id,
          text: item.org_name
        };
        this.selectedGSTNumber = null;
        this.selectedPeriodFrom = this.defaultValue;
        this.selectedPeriodTo = this.defaultValue;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.GST_FIRST_PARTY_GSTN
      ) {
        this.selectedGSTNumber = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.GL_PERIOD) {
        if (this.periodType === 'periodFrom') {
          this.selectedPeriodFrom = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
        } else if (this.periodType === 'periodTo') {
          this.selectedPeriodTo = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
        }
      } else if (this.vsetCode === appStrings.VALUESETTYPE.REV_FLAG_GST) {
        this.revGst = {
          value: item.value_code,
          text: item.value_meaning
        };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.RECOVERABLE_FLAG_GST
      ) {
        this.recoGst = {
          value: item.value_code,
          text: item.value_meaning
        };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.GST_RECONCILATION_STATUS_NEW
      ) {
        this.recoStatus = {
          value: item.value_code,
          text: item.value_meaning
        };
        // this.showValueSetModal = false;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.YES_NO) {
        this.selectedNearMatch = {
          value: item.value_code,
          text: item.value_meaning
        };
        if(this.selectedNearMatch.value === 'Y') {
          this.recoStatus = {
            value: '0',
            text: 'UnReconciled'
          };
        } else {
          this.recoStatus = this.defaultValue;
        }
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clear() {
      this.leName = this.defaultValue;
      this.selectedGSTNumber = null;
      this.selectedPeriodFrom = this.defaultValue;
      this.selectedPeriodTo = this.defaultValue;
      this.vendorPan = null;
      this.vendorName = null;
      this.tpGstn = null;
      this.invNum = null;
      this.totalRows = null;
      this.searchParams = null;
      this.requestParams = {};
      this.revGst = this.defaultValue;
      this.recoGst = this.defaultValue;
      this.recoStatus = this.defaultValue;
      this.selectedNearMatch = this.defaultValue;
    },
    clearVsetValues(vsetCode, periodType) {
      if (vsetCode === this.leName.value) {
        this.leName = this.defaultValue;
        this.selectedGSTNumber = null;
        this.selectedPeriodFrom = this.defaultValue;
        this.selectedPeriodTo = this.defaultValue;
      } else if (vsetCode === this.selectedGSTNumber.value) {
        this.selectedGSTNumber = null;
      } else if (vsetCode === this.selectedNearMatch.text) {
        this.selectedNearMatch.text = null;
        this.selectedNearMatch.value = null;
      } else if (vsetCode === appStrings.VALUESETTYPE.GL_PERIOD) {
        if (periodType === 'periodFrom') {
          this.selectedPeriodFrom = this.defaultValue;
        } else if (periodType === 'periodTo') {
          this.selectedPeriodTo = this.defaultValue;
        }
      } else if (vsetCode === this.revGst.value) {
        this.revGst.text = null;
        this.revGst.value = null;
      } else if (vsetCode === this.recoGst.text) {
        this.recoGst.text = null;
        this.recoGst.value = null;
      } else if (vsetCode === this.recoStatus.value) {
        this.recoStatus.text = null;
        this.recoStatus.value = null;
      }
      // this.vendorPan = null;
      // this.tpGstn = null;
      // this.invNum = null;
    },
    maximize() {
      this.minimizeFlag = !this.minimizeFlag;
    }
  },
  beforeDestroy() {
    // this.unsubscribe();
  }
};
