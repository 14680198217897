import appStrings from '@/app/utility/string.utility';
import gstrDetails from './gstrDetails';
import commonHelper from '@/app/utility/common.helper.utility';
export default {
  watch: {
    currentPage: function() {
      this.getGstrTwoDetails()
        // ? this.getGstrTwoDetails()
        // : this.getGstrTwoDetailsData();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getGstrTwoDetails()
        // ? this.getGstrTwoDetails()
        // : this.getGstrTwoDetailsData();
    }
  },
  name: 'gstrTwoDetails',
  props: ['rowDetails', 'searchFilters'],
  components: {
    gstrDetails
  },
  data() {
    return {
      selectAllCheckBox: false,
      showHistoryBtn: false,
      taxableAmount: 0,
      totalTax: 0,
      totalReconciled: 0,
      totalUnreconciled: 0,
      selectedRow: [],
      minimizeFlag: true,
      currentPage: 1,
      totalRows: null,
      perPage: commonHelper.perPageData,
      pageOptions: commonHelper.getPageOptionsList(),
      loading: false,
      defaultValue: {
        value: null,
        text: null
      },
      leName: {
        value: null,
        text: null
      },
      selectedGSTNumber: null,
      vendorPan: null,
      vendorName: null,
      selectedPeriodFrom: {
        value: null,
        text: null
      },
      selectedPeriodTo: {
        value: null,
        text: null
      },
      selectedItcEligible: {
        value: null,
        text: null
      },
      selectedReverseCharge: {
        value: null,
        text: null
      },
      recoStatus: {
        value: '0',
        text: 'UnReconciled'
      },
      gstrTwoDetailsData: [],
      gstrTwoDetailsFields: [
        {
          key: 'selectBox',
          label: 'Select',
          stickyColumn: true,
          variant: 'info',
          class: 'stickyColumn col-sm-1'
        },
        {
          key: 'fp_gstn',
          label: 'FP GSTN',
          variant: 'success',
          class: 'col-fix'
        },
        {
          key: 'vendor_name'
        },
        {
          key: 'vendor_pan',
          label: 'Vendor PAN',
          class: 'text-center col-fix'
        },
        {
          key: 'taxable_amount',
          class: 'text-center col-fix'
        },
        {
          key: 'total_tax',
          class: 'text-center col-fix'
        },
        {
          key: 'rec_tax',
          class: 'text-center col-fix'
        },
        {
          key: 'rec_cgst',
          class: 'd-total-rectax text-center col-fix',
          variant: 'success'
        },
        {
          key: 'rec_igst',
          class: 'd-total-rectax text-center col-fix',
          variant: 'success'
        },
        {
          key: 'rec_sgst',
          class: 'd-total-rectax text-center col-fix',
          variant: 'success'
        },
        {
          key: 'unrec_tax',
          class: 'text-center col-fix'
        },
        {
          key: 'unrec_cgst',
          class: 'd-total-unreco text-center col-fix',
          variant: 'success'
        },
        {
          key: 'unrec_igst',
          class: 'd-total-unreco text-center col-fix',
          variant: 'success'
        },
        {
          key: 'unrec_sgst',
          class: 'd-total-unreco text-center col-fix',
          variant: 'success'
        }
      ],
      showValueSetModal: false,
      periodType: null,
      showOpenGstrDetailsModal: false,
      rowGstrDetails: null,
      searchGstrFilters: null,
      downloadPayload: null
    };
  },
  mounted() {
    if(this.searchFilters) {
      this.leName = {
        value: this.searchFilters.le_id,
        text: this.searchFilters.leName
      };
      this.selectedPeriodFrom = {
        value: this.searchFilters.period_start_date,
        text: this.searchFilters.selectedPeriodFrom
      };
      this.selectedPeriodTo = {
        value: this.searchFilters.period_end_date,
        text: this.searchFilters.selectedPeriodTo
      };
      this.selectedGSTNumber = this.rowDetails.first_party_gstn;
      this.selectedItcEligible = {
        value: this.searchFilters.itc_elg,
        text: this.searchFilters.selectedItcEligible
      };
      this.selectedReverseCharge = {
        value: this.searchFilters.reverse_charge,
        text: this.searchFilters.selectedReverseCharge
      };
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (
          actionName === 'download' &&
          !this.showOpenGstrDetailsModal &&
          !this.showGstrNearMatch
        ) {
          this.loading = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'complainceDashboard/getGstrTwoDetailsData',
            'comp',
            () => (this.loading = false)
          );
        }
      }
    });
    this.getGstrTwoDetails();
    if (this.searchFilters) {
      this.getParentToChildData(this.searchFilters);
    }
  },
  methods: {
    downloadExcelData() {
      this.loader = true;
      const downloadpayload = {...this.downloadPayload};
      downloadpayload._limit = this.totalRows;
      this.downloadExcel.downloadData(
        downloadpayload,
        'complainceDashboard/getGstrTwoDetails',
        'gstr-two-details',
      () => (this.loader = false)
      );
    },
    formattedNumber(num) {
      return commonHelper.formatCurrency(num);
    },
    selectAllBoxChecked(flag) {
      this.selectedRow = [];
      if (flag) {
        this.gstrTwoDetailsData = this.gstrTwoDetailsData.map((data, index) => {
          data.selectBox = this.selectAllCheckBox;
          if (this.selectAllCheckBox) {
            this.selectBoxChecked(data.selectBox, index);
          }
          return data;
        });
        this.selectedRow = this.gstrTwoDetailsData;
        for (let i of this.selectedRow) {
          this.taxableAmount = this.taxableAmount + i.taxable_amount;
          this.totalTax = this.totalTax + i.total_tax;
          this.totalReconciled = this.totalReconciled + i.rec_tax;
          this.totalUnreconciled = this.totalUnreconciled + i.unrec_tax;
        }
      } else {
        this.gstrTwoDetailsData = this.gstrTwoDetailsData.map((data, index) => {
          data.selectBox = this.selectAllCheckBox;
          if (this.selectAllCheckBox) {
            this.selectBoxChecked(data.selectBox, index);
          }
          return data;
        });
        this.selectedRow = [];
        this.taxableAmount = 0;
        this.totalTax = 0;
        this.totalReconciled = 0;
        this.totalUnreconciled = 0;
        for (let i of this.gstrTwoDetailsData) {
          this.taxableAmount = this.taxableAmount + i.taxable_amount;
          this.totalTax = this.totalTax + i.total_tax;
          this.totalReconciled = this.totalReconciled + i.rec_tax;
          this.totalUnreconciled = this.totalUnreconciled + i.unrec_tax;
        }
        this.showHistoryBtn = false;
      }
    },
    selectBoxChecked(flag, index, item, cgst) {
      this.taxableAmount = 0;
      this.totalTax = 0;
      this.totalReconciled = 0;
      this.totalUnreconciled = 0;
      this.gstrTwoDetailsData[index].selectBox = flag;
      if (flag === true) {
        this.selectedRow.push(item);
      } else {
        this.selectedRow = this.selectedRow.filter(
          el => el.unrec_cgst !== cgst
        );
      }
      if (this.selectedRow.length > 0) {
        this.showHistoryBtn = true;
      } else {
        this.showHistoryBtn = false;
      }
    },
    showTotal() {
      let taxValue = 0;
      let totTax = 0;
      let totRec = 0;
      let totUnrec = 0;
      for (let i of this.selectedRow) {
        taxValue = taxValue + i.taxable_amount;
        totTax = totTax + i.total_tax;
        totRec = totRec + i.rec_tax;
        totUnrec = totUnrec + i.unrec_tax;
      }

      this.taxableAmount = taxValue;
      this.totalTax = totTax;
      this.totalReconciled = totRec;
      this.totalUnreconciled = totUnrec;
    },
    getParentToChildData(item) {
      this.leName.value = item.le_id;
      this.leName.text = item.leName;
      this.selectedPeriodFrom.text = item.period_start_date;
      this.selectedPeriodTo.text = item.period_end_date;
      this.selectedPeriodFrom.value = item.period_start_date;
      this.selectedPeriodTo.value = item.period_end_date;
      this.selectedGSTNumber = this.rowDetails.first_party_gstn;
      this.selectedReverseCharge.value = item.reverse_charge;
      this.selectedReverseCharge.text =item.selectedReverseCharge;
      this.selectedItcEligible.text = item.selectedItcEligible;
      this.selectedItcEligible.value = item.itc_elg;
    },
    getGstrTwoDetails() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        le_id: this.leName.value,
        gstn_no: this.selectedGSTNumber,
        period_start_date: this.selectedPeriodFrom.value,
        period_end_date: this.selectedPeriodTo.value,
        vendor_pan: this.vendorPan ? this.vendorPan : null,
        vendor_name: this.vendorName ? this.vendorName : null,
        rev_charge: this.selectedReverseCharge.value,
        recoverable_charge: this.selectedItcEligible.value,
        reco_flag2b: this.recoStatus.value,
      };
      this.downloadPayload = payload;
      this.loading = true;
      this.taxableAmount = 0;
      this.totalTax = 0;
      this.totalReconciled = 0;
      this.totalUnreconciled = 0;
      this.$store
        .dispatch('complainceDashboard/getGstrTwoDetails', payload)
        .then(response => {
          this.loading = false;
          if (response.status === 200) {
            this.gstrTwoDetailsData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
            for (let i of this.gstrTwoDetailsData) {
              this.taxableAmount = this.taxableAmount + i.taxable_amount;
              this.totalTax = this.totalTax + i.total_tax;
              this.totalReconciled = this.totalReconciled + i.rec_tax;
              this.totalUnreconciled = this.totalUnreconciled + i.unrec_tax;
            }
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // getGstrTwoDetailsData() {
    //   const payload = {
    //     _page: this.currentPage - 1,
    //     _limit: this.perPage,
    //     le_id: this.leName.value,
    //     gstn_no: this.selectedGSTNumber,
    //     period_start_date: this.selectedPeriodFrom.value,
    //     period_end_date: this.selectedPeriodTo.value,
    //     vendor_pan: this.vendorPan ? this.vendorPan : null,
    //     vendor_name: this.vendorName ? this.vendorName : null,
    //     rev_charge: this.selectedItcEligible.value,
    //     recoverable_charge: this.selectedReverseCharge.value,
    //   };
    //   this.loading = true;
    //   this.taxableAmount = 0;
    //   this.totalTax = 0;
    //   this.totalReconciled = 0;
    //   this.totalUnreconciled = 0;
    //   this.$store
    //     .dispatch('complainceDashboard/getGstrTwoDetails', payload)
    //     .then(response => {
    //       this.loading = false;
    //       if (response.status === 200) {
    //         this.gstrTwoDetailsData = response.data.data.page;
    //         this.totalRows = response.data.data.total_elements;
    //         for (let i of this.gstrTwoDetailsData) {
    //           this.taxableAmount = this.taxableAmount + i.taxable_amount;
    //           this.totalTax = this.totalTax + i.total_tax;
    //           this.totalReconciled = this.totalReconciled + i.rec_tax;
    //           this.totalUnreconciled = this.totalUnreconciled + i.unrec_tax;
    //         }
    //       }
    //     })
    //     .catch(() => {
    //       this.loading = false;
    //     });
    // },
    expandColumn(data, class1, class2) {
      let elem = document.querySelectorAll(`.${class1}`);
      if (elem && elem[0] && elem[0].classList.contains(class1)) {
        if (data === 'rec_tax') {
          elem.forEach(el => {
            el.classList.replace('d-total-rectax', class2);
          });
        } else {
          elem.forEach(el => {
            el.classList.replace('d-total-unreco', class2);
          });
        }
      } else {
        if (data === 'rec_tax') {
          let otherElem = document.querySelectorAll('.other-total-rectax');
          otherElem.forEach(el => {
            el.classList.replace('other-total-rectax', 'd-total-rectax');
          });
        } else {
          let otherElem = document.querySelectorAll('.other-total-unreco');
          otherElem.forEach(el => {
            el.classList.replace('other-total-unreco', 'd-total-unreco');
          });
        }
      }
    },
    openValueSetModal(vsetCode, periodType,  valueType) {
      this.showValueSetModal = true;
      this.vsetCode = vsetCode;
      this.periodType = periodType;
      this.valueType = valueType;
      this.parent_value_set_id = null;
      if (
        this.vsetCode === appStrings.VALUESETTYPE.GST_FIRST_PARTY_GSTN ||
        this.vsetCode === appStrings.VALUESETTYPE.GL_PERIOD
      ) {
        this.parent_value_set_id = this.leName.value;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.leName = {
          value: item.org_id,
          text: item.org_name
        };
        this.selectedGSTNumber = null;
        this.selectedPeriodFrom = this.defaultValue;
        this.selectedPeriodTo = this.defaultValue;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.GST_FIRST_PARTY_GSTN
      ) {
        this.selectedGSTNumber = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.GL_PERIOD) {
        if (this.periodType === 'periodFrom') {
          this.selectedPeriodFrom = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
        } else if (this.periodType === 'periodTo') {
          this.selectedPeriodTo = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
        }
      } else if (this.vsetCode === appStrings.VALUESETTYPE.REV_FLAG_GST) {
        this.selectedReverseCharge = {
          value: item.value_code,
          text: item.value_meaning
        };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.RECOVERABLE_FLAG_GST
      ) {
        this.selectedItcEligible = {
          value: item.value_code,
          text: item.value_meaning
        };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.GST_RECONCILATION_STATUS_NEW
      ) {
        this.recoStatus = {
          value: item.value_code,
          text: item.value_meaning
        };
        // this.showValueSetModal = false;
      }
      // this.showValueSetModal = false;
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clear() {
      this.leName = this.defaultValue;
      this.selectedGSTNumber = null;
      this.selectedPeriodFrom = this.defaultValue;
      this.selectedPeriodTo = this.defaultValue;
      this.vendorPan = null;
      this.vendorName = null;
      this.selectedItcEligible = this.defaultValue;
      this.selectedReverseCharge = this.defaultValue;
      this.totalRows = null;
      this.recoStatus = this.defaultValue;
      this.searchParams = null;
      this.requestParams = {};
    },
    clearVsetValues(vsetCode, periodType) {
      if (vsetCode === this.leName.value) {
        this.leName = this.defaultValue;
        this.selectedGSTNumber = null;
        this.selectedPeriodFrom = this.defaultValue;
        this.selectedPeriodTo = this.defaultValue;
      } else if (vsetCode === this.selectedGSTNumber.value) {
        this.selectedGSTNumber = null;
      } else if (vsetCode === appStrings.VALUESETTYPE.GL_PERIOD) {
        if (periodType === 'periodFrom') {
          this.selectedPeriodFrom = this.defaultValue;
        } else if (periodType === 'periodTo') {
          this.selectedPeriodTo = this.defaultValue;
        }
      } else if (vsetCode === this.selectedItcEligible.value) {
        this.selectedItcEligible = this.defaultValue;
      } else if (vsetCode === this.selectedReverseCharge.value) {
        this.selectedReverseCharge = this.defaultValue;
      } else if(vsetCode === this.recoStatus.value) {
        this.recoStatus = this.defaultValue;
      }
    },
    gstrDetailsModal(flag) {
      this.showOpenGstrDetailsModal = flag;
    },
    rowSelected(items) {
      this.showOpenGstrDetailsModal = true;
      this.rowGstrDetails = items;
      this.searchGstrFilters = this.searchFilters;
    },
    maximize() {
      this.minimizeFlag = !this.minimizeFlag;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
