<template>
  <div>
    <div id="chart-container3"></div>
  </div>
</template>

<script>
import Highcharts from 'highcharts';
import Highcharts3D from 'highcharts/highcharts-3d';
import highchartsDark from 'highcharts/themes/dark-unica';
Highcharts3D(Highcharts);
highchartsDark(Highcharts);

export default {
  name: 'barChart',
  props: ['chart', 'cat'],
  mounted() {
   const chart = Highcharts.chart('chart-container3', {
      chart: {
        type: 'column',
        options3d: {
          enabled: true,
          alpha: 20,
          beta: 35,
          depth: 80
        }
      },
      height: 600,
      width: 600,
      title: {
        text: 'Reco%'
      },
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true
          }
        }
      },
      xAxis: {
        categories: this.cat
      },
      yAxis: {
        title: {
          text: 'Value'
        }
      },
      series: [
        {
          name: '          ',
          colorByPoint: true,
          colors: [
            '#E76161',
            '#FFD95A',
            '#B0DAFF',
            '#ACB1D6',
            '#E06469',
            '#394867',
            '#6C9BCF'
          ],
          size: '60%',
          data: this.chart
        }
      ]
    });
    const renderer = chart.renderer;
    renderer.text('GSTN Number', 10, 350).css({
      color: 'white',
      fontSize: '20px',
      fontWeight: 'bolder'
    }).attr({
      zIndex: 5
    }).add();
  }
};
</script>
