import commonHelper from '@/app/utility/common.helper.utility';
import gstrTwoBDeatils from './gstrTwoBDetails';
import pieChart1 from '../pieChart/pieChart1';
import pieChart2 from '../pieChart/pieChart2';
export default {
  name: 'gstrTwoB',
  components: {
    gstrTwoBDeatils,
    pieChart1,
    pieChart2
  },
  props: ['searchParams', 'selectedItcEligible', 'selectedReverseCharge'],
  watch: {
    searchParams() {
      if (this.searchParams) {
        this.getGstrTwoBList(this.searchParams);
      } else {
        this.gstrTwobData = [];
      }
    },
    currentPage: function() {
      this.searchParams._page = this.currentPage - 1;
      this.getGstrTwoBList(this.searchParams);
    },
    totalRows() {
      this.$emit('totalRowsCount', this.totalRows);
    }
  },
  data() {
    return {
      chart: '',
      showPieChartModal: false,
      selectAllCheckBox: false,
      showHistoryBtn: false,
      totallines: 0,
      totalTax: 0,
      totalReconciled: 0,
      totalUnreconciled: 0,
      recLines: 0,
      selectedRow: [],
      chartData: [],
      chartData1: [],
      chartData2: [],
      selectedItcEligibleData: {
        value: null,
        text: null
      },
      selectedReverseChargeData: {
        value: null,
        text: null
      },
      cate: [],
      minimizeFlag: true,
      loading: false,
      currentPage: 1,
      perPage: commonHelper.perPageData,
      pageOptions: commonHelper.getPageOptionsList(),
      totalRows: null,
      searchValue: {
        gstn_no: null,
        period_start_date: null,
        le_id: null,
        period_end_date: null
      },
      gstrTwobData: [],
      gstrTwobFields: [
        {
          key: 'selectBox',
          label: 'Select',
          stickyColumn: true,
          variant: 'info',
          class: 'stickyColumn col-sm-1'
        },
        {
          key: 'first_party_gstn',
          label: 'First Party GSTN',
          variant: 'success',
          class: 'col-fix'
        },
        {
          key: 'itc_ne',
          label: 'ITC NE',
          class: 'col-fix'
        },
        {
          key: 'total_lines',
          class: 'col-fix'
        },
        {
          key: 'unrec_lines',
          class: 'd-total-line',
          stickyColumn: true,
          variant: 'success'
        },
        {
          key: 'rec_lines',
          class: 'd-total-line',
          stickyColumn: true,
          variant: 'success'
        },
        {
          key: 'total_tax',
          class: 'col-fix'
        },
        {
          key: 'igst',
          class: 'd-total-tax',
          label: 'IGST',
          variant: 'success'
        },
        {
          key: 'cgst',
          class: 'd-total-tax',
          label: 'CGST',
          variant: 'success'
        },
        {
          key: 'sgst',
          class: 'd-total-tax',
          label: 'SGST',
          variant: 'success'
        },
        {
          key: 'rec_amount',
          class: 'col-fix'
        },
        {
          key: 'rec_igst',
          class: 'd-total-rec',
          label: 'Rec IGST',
          variant: 'success'
        },
        {
          key: 'rec_cgst',
          class: 'd-total-rec',
          label: 'Rec CGST',
          variant: 'success'
        },
        {
          key: 'rec_sgst',
          class: 'd-total-rec',
          label: 'Rec SGST',
          variant: 'success'
        },
        {
          key: 'unrec_amount',
          class: 'col-fix'
        },
        {
          key: 'unrec_igst',
          label: 'Unrec IGST',
          class: 'd-total-unrec',
          variant: 'success'
        },
        {
          key: 'unrec_cgst',
          label: 'Unrec CGST',
          class: 'd-total-unrec',
          variant: 'success'
        },
        {
          key: 'unrec_sgst',
          label: 'Unrec SGST',
          class: 'd-total-unrec',
          variant: 'success'
        },
        {
          key: 'reco_per',
          label: 'Reco %',
          class: 'col-fix'
        }
      ],
      showOpenGstrTwoBModal: false,
      rowDetails: null,
      searchFilters: null
    };
  },
  mounted() {
    if(this.searchParams.le_id && this.searchParams.selectedPeriodFrom){
      this.getGstrTwoBList();
    }
  },
  methods: {
    formattedNumber(num) {
      return commonHelper.formatCurrency(num);
    },
    selectAllBoxChecked(flag) {
      this.selectedRow = [];
      if (flag) {
        this.gstrTwobData = this.gstrTwobData.map((data, index) => {
          data.selectBox = this.selectAllCheckBox;
          if (this.selectAllCheckBox) {
            this.selectBoxChecked(data.selectBox, index);
          }
          return data;
        });
        this.selectedRow = this.gstrTwobData;
        for (let i of this.selectedRow) {
          this.totallines = this.totallines + i.total_lines;
          this.totalTax = this.totalTax + i.total_tax;
          this.recLines = this.recLines + i.rec_lines;
          this.totalReconciled = this.totalReconciled + i.rec_amount;
          this.totalUnreconciled = this.totalUnreconciled + i.unrec_amount;
        }
      } else {
        this.gstrTwobData = this.gstrTwobData.map((data, index) => {
          data.selectBox = this.selectAllCheckBox;
          if (this.selectAllCheckBox) {
            this.selectBoxChecked(data.selectBox, index);
          }
          return data;
        });
        this.selectedRow = [];
        this.totallines = 0;
        this.totalTax = 0;
        this.recLines = 0;
        this.totalReconciled = 0;
        this.totalUnreconciled = 0;
        for (let i of this.gstrTwobData) {
          this.totallines = this.totallines + i.total_lines;
          this.totalTax = this.totalTax + i.total_tax;
          this.recLines = this.recLines + i.rec_lines;
          this.totalReconciled = this.totalReconciled + i.rec_amount;
          this.totalUnreconciled = this.totalUnreconciled + i.unrec_amount;
        }
        this.showHistoryBtn = false;
      }
    },
    selectBoxChecked(flag, index, item, cgst) {
      this.totallines = 0;
      this.totalTax = 0;
      this.recLines = 0;
      this.totalReconciled = 0;
      this.totalUnreconciled = 0;
      this.gstrTwobData[index].selectBox = flag;
      if (flag === true) {
        this.selectedRow.push(item);
      } else {
        this.selectedRow = this.selectedRow.filter(el => el.cgst !== cgst);
      }
      if (this.selectedRow.length > 0) {
        this.showHistoryBtn = true;
      } else {
        this.showHistoryBtn = false;
      }
    },
    showTotal() {
      let totLines = 0;
      let totTax = 0;
      let totRec = 0;
      let totUnrec = 0;
      let recLine = 0;
      for (let i of this.selectedRow) {
        totLines = totLines + i.total_lines;
        totTax = totTax + i.total_tax;
        totRec = totRec + i.rec_amount;
        totUnrec = totUnrec + i.unrec_amount;
        recLine = recLine + i.rec_lines;
      }

      this.totallines = totLines;
      this.totalTax = totTax;
      this.recLines = recLine;
      this.totalReconciled = totRec;
      this.totalUnreconciled = totUnrec;
    },
    totalLines() {
      this.showPieChartModal = true;
      this.chart = 'SHOW CHARTS';
      let unrecLines = 0;
      let recLines = 0;
      let rectax = 0;
      let unrectax = 0;
      let gstn = [];
      let reco = [];
      for (let i of this.selectedRow) {
        unrecLines = unrecLines + i.unrec_lines;
        recLines = recLines + i.rec_lines;
        rectax = rectax + i.rec_amount;
        unrectax = unrectax + i.unrec_amount;
        gstn.push(i.first_party_gstn);
        reco.push(Number(i.total_rec_amount_prcnt));
      }

      if (unrecLines === 0 && recLines === 0) {
        this.showHistoryBtn = false;
      }
      this.chartData = [
        ['Reconciled Lines', recLines],
        ['Unreconciled Lines', unrecLines]
      ];
      this.chartData1 = [
        ['Total Reconciled', rectax],
        ['Total Unreconciled', unrectax]
      ];
      this.cate = gstn;
      this.chartData2 = reco;
    },
    getGstrTwoBList(params) {
      let payload = this.searchParams;
      if (params) {
        payload = Object.assign(payload, params);
      }
      this.loading = true;
      this.$store.commit('complainceDashboard/setLoading', this.loading)
      this.totallines = 0;
      this.totalTax = 0;
      this.recLines = 0;
      this.totalReconciled = 0;
      this.totalUnreconciled = 0;
      this.$store
        .dispatch('complainceDashboard/getGstrTwoBList', payload)
        .then(response => {
          if (response.status === 200) {
            this.gstrTwobData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
            for (let i of this.gstrTwobData) {
                this.totallines = this.totallines + i.total_lines;
                this.totalTax = this.totalTax + i.total_tax;
                this.recLines = this.recLines + i.rec_lines;
                this.totalReconciled = this.totalReconciled + i.rec_amount;
                this.totalUnreconciled = this.totalUnreconciled + i.unrec_amount;
              }
            this.loading = false;
            this.$store.commit('complainceDashboard/setLoading', this.loading)
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    expandColumn(data, class1, class2) {
      let elem = document.querySelectorAll(`.${class1}`);
      if (elem && elem[0] && elem[0].classList.contains(class1)) {
        if (data === 'total_lines') {
          elem.forEach(el => {
            el.classList.replace('d-total-line', class2);
          });
        } else {
          elem.forEach(el => {
            el.classList.replace('d-total-tax', class2);
          });
        }
        if (data === 'rec_amount') {
          elem.forEach(el => {
            el.classList.replace('d-total-rec', class2);
          });
        } else {
          elem.forEach(el => {
            el.classList.replace('d-total-unrec', class2);
          });
        }
      } else {
        if (data === 'total_lines') {
          let otherElem = document.querySelectorAll('.other-total-line');
          otherElem.forEach(el => {
            el.classList.replace('other-total-line', 'd-total-line');
          });
        } else {
          let otherElem = document.querySelectorAll('.other-total-tax');
          otherElem.forEach(el => {
            el.classList.replace('other-total-tax', 'd-total-tax');
          });
        }
        if (data === 'rec_amount') {
          let otherElem = document.querySelectorAll('.other-total-rec');
          otherElem.forEach(el => {
            el.classList.replace('other-total-rec', 'd-total-rec');
          });
        } else {
          let otherElem = document.querySelectorAll('.other-total-unrec');
          otherElem.forEach(el => {
            el.classList.replace('other-total-unrec', 'd-total-unrec');
          });
        }
      }
    },
    gstrTwoBDetailsModal(flag) {
      this.showOpenGstrTwoBModal = flag;
    },
    rowSelected(items) {  
      this.showOpenGstrTwoBModal = true;
      this.rowDetails = items;
      this.searchFilters = this.searchParams;
      this.selectedItcEligibleData = this.selectedItcEligible;
      this.selectedReverseChargeData = this.selectedReverseCharge;
    },
    pieChartModal(flag) {
      this.showPieChartModal = flag;
    },
    maximize() {
      this.minimizeFlag = !this.minimizeFlag;
    }
  }
};
