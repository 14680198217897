import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import gstr3BScr4 from './gstr3BScr4';

export default {
  name: 'gstrThreeBScr3',
  watch: {
    currentPage: function() {
      this.getGstr3bScr3Data();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getGstr3bScr3Data();
    }
  },
  components: { gstr3BScr4 },
  props: ['rowDtlsScr2', 'searchFilterScr3'],
  data() {
    return {
      loading: false,
      totalRecoAmt: 0,
      totalCorrRecon: 0,
      totalAutoRecon: 0,
      totalManualRecon: 0,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      gstrThree3Data: [],
      gstrThree3Fields: [
        {
          key: 'selectBox',
          label: 'Select',
          stickyColumn: true,
          variant: 'info',
          class: 'stickyColumn col-sm-1'
        },
        { key: 'business_performance' },
        { key: 'fp_gstn', label: 'FP GSTN' },
        { key: 'gl_prd', label: 'GL PRD' },
        { key: 'gstr2b_prd', label: 'GSTR2B PRD' },
        { key: 'gstr3b_period', label: 'GSTR3B Period' },
        { key: 'reconciled_amount', label: 'Reconciled Amount' },
        {
          key: 'igst',
          label: 'IGST',
          variant: 'success',
          class: 'text-center d-total-line'
        },

        {
          key: 'cgst',
          label: 'CGST',
          variant: 'success',
          class: 'text-center d-total-line'
        },
        {
          key: 'sgst',
          label: 'SGST',
          variant: 'success',
          class: 'text-center d-total-line'
        },
        { key: 'auto_recon', label: 'Auto Recon' },
        { key: 'correction_recon', label: 'Correction Recon' },
        { key: 'manaul_recon', label: 'Manaul Recon' }
      ],
      gstrGlPrd: {
        text: null,
        value: null
      },
      gstr2bPrd: {
        text: null,
        value: null
      },
      selectAllCheckBox: false,
      selectedRow: [],
      showValueSetModal: false,
      showOpenGstr3BScr4Modal: false,
      rowDtlsScr3: null,
      minimizeFlag: true,
      prdType: null,
      unsubscribe: null,
      payload: null,
      accessButtons: {
        deleteAllow: false,
        downloadAllow: true,
        uploadAllow: false,
        editAllow: false,
        addAllow: false,
        printAllow: false,
        approvalAllow: false
      }
    };
  },
  mounted() {
    if (this.rowDtlsScr2 && this.searchFilterScr3) {
      this.getGstr3bScr3Data();
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'download' && !this.showOpenGstr3BScr4Modal) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = 1000;
          this.downloadExcel.downloadData(
            downloadpayload,
            'complainceDashboard/complianceTab3scr2',
            'Business-Performance-Period-Wise',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    getGstr3bScr3Data() {
      this.payload = {
        gstr3b_period: this.searchFilterScr3.gstr3bPeriod,
        fp_gst: this.searchFilterScr3.fpGstn,
        le_id: this.searchFilterScr3.leId,
        gstr2b_prd: this.gstr2bPrd.value,
        gl_prd: this.gstrGlPrd.value,
        business_performance: this.rowDtlsScr2.business_performance,
        _page: this.currentPage - 1,
        _limit: this.perPage
      };
      this.totalRecoAmt = 0;
      this.totalCorrRecon = 0;
      this.totalAutoRecon = 0;
      this.totalManualRecon = 0;
      this.loading = true;
      this.$store
        .dispatch('complainceDashboard/complianceTab3scr2', this.payload)
        .then(response => {
          if (response.status === 200) {
            const result = response.data.data.page;
            this.gstrThree3Data = result;
            for (let i of this.gstrThree3Data) {
              this.totalRecoAmt = this.totalRecoAmt + i.reconciled_amount;
              this.totalCorrRecon = this.totalCorrRecon + i.correction_recon;
              this.totalAutoRecon = this.totalAutoRecon + i.auto_recon;
              this.totalManualRecon = this.totalManualRecon + i.manaul_recon;
            }
            this.totalRows = response.data.data.total_elements;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    selectAllBoxChecked(flag) {
      this.selectedRow = [];
      if (flag) {
        this.gstrThree3Data = this.gstrThree3Data.map((data, index) => {
          data.selectBox = this.selectAllCheckBox;
          if (this.selectAllCheckBox) {
            this.selectBoxChecked(data.selectBox, index);
          }
          return data;
        });
        this.selectedRow = this.gstrThree3Data;
        for (let i of this.selectedRow) {
          this.totalRecoAmt = this.totalRecoAmt + i.reconciled_amount;
          this.totalAutoRecon = this.totalAutoRecon + i.auto_recon;
          this.totalCorrRecon = this.totalCorrRecon + i.correction_recon;
          this.totalManualRecon = this.totalManualRecon + i.manaul_recon;
        }
      } else {
        this.gstrThree3Data = this.gstrThree3Data.map((data, index) => {
          data.selectBox = this.selectAllCheckBox;
          if (this.selectAllCheckBox) {
            this.selectBoxChecked(data.selectBox, index);
          }
          return data;
        });
        this.selectedRow = [];
        this.totalRecoAmt = 0;
        this.totalCorrRecon = 0;
        this.totalAutoRecon = 0;
        this.totalManualRecon = 0;
        for (let i of this.gstrThree3Data) {
          this.totalRecoAmt = this.totalRecoAmt + i.reconciled_amount;
          this.totalAutoRecon = this.totalAutoRecon + i.auto_recon;
          this.totalCorrRecon = this.totalCorrRecon + i.correction_recon;
          this.totalManualRecon = this.totalManualRecon + i.manaul_recon;
        }
      }
    },
    selectBoxChecked(flag, index, item, reconciledAmount) {
      this.totalRecoAmt = 0;
      this.totalCorrRecon = 0;
      this.totalAutoRecon = 0;
      this.totalManualRecon = 0;
      this.gstrThree3Data[index].selectBox = flag;
      if (flag === true) {
        this.selectedRow.push(item);
      } else {
        this.selectedRow = this.selectedRow.filter(
          el => el.reconciled_amount !== reconciledAmount
        );
      }
    },
    showTotal() {
      let totRecAmt = 0;
      let totAutoReco = 0;
      let totCorrReco = 0;
      let totManReco = 0;
      for (let i of this.selectedRow) {
        totRecAmt = totRecAmt + i.reconciled_amount;
        totAutoReco = totAutoReco + i.auto_recon;
        totCorrReco = totCorrReco + i.correction_recon;
        totManReco = totManReco + i.manaul_recon;
      }
      this.totalRecoAmt = totRecAmt;
      this.totalCorrRecon = totCorrReco;
      this.totalAutoRecon = totAutoReco;
      this.totalManualRecon = totManReco;
    },
    formattedNumber(num) {
      return commonHelper.formatCurrency(num);
    },
    openValueSetModal(vsetCode, glType) {
      this.showValueSetModal = true;
      this.vsetCode = vsetCode;
      this.prdType = glType;
      this.parent_value_set_id = null;
      if (this.vsetCode === appStrings.VALUESETTYPE.GL_PERIOD) {
        this.parent_value_set_id = this.searchFilterScr3.leId;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.GL_PERIOD) {
        if (this.prdType === '2BPrd') {
          this.gstr2bPrd = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
        } else if (this.prdType === 'glPrd') {
          this.gstrGlPrd = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
        }
      }
      this.showValueSetModal = false;
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.gstr2bPrd.value) {
        this.gstr2bPrd = {
          text: null,
          value: null
        };
      } else if (vsetCode === this.gstrGlPrd.value) {
        this.gstrGlPrd = {
          text: null,
          value: null
        };
      }
    },
    clearSearchFilters() {
      this.gstr2bPrd = {
        text: null,
        value: null
      };
      this.gstrGlPrd = {
        text: null,
        value: null
      };
    },
    showHideGstr3BScr4Modal(flag) {
      this.showOpenGstr3BScr4Modal = flag;
    },
    rowSelected(item) {
      this.showOpenGstr3BScr4Modal = true;
      this.rowDtlsScr3 = item;
    },
    maximize() {
      this.minimizeFlag = !this.minimizeFlag;
    },
    expandColumn(data, class1, class2) {
      let elem = document.querySelectorAll(`.${class1}`);
      if (elem && elem[0] && elem[0].classList.contains(class1)) {
        if (data === 'reconciled_amount') {
          elem.forEach(el => {
            el.classList.replace('d-total-line', class2);
          });
        }
      } else {
        if (data === 'reconciled_amount') {
          let otherElem = document.querySelectorAll('.other-total-line');
          otherElem.forEach(el => {
            el.classList.replace('other-total-line', 'd-total-line');
          });
        }
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
