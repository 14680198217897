import commonHelper from '@/app/utility/common.helper.utility';
import gstrThreeBScr3 from '../gstrThreeBScr3';
export default {
  name: 'gstrThreeBScr2',
  watch: {
    currentPage: function() {
      this.getGstr3bScr2Data();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getGstr3bScr2Data();
    }
  },
  components: { gstrThreeBScr3 },
  props: ['rowDetails', 'rowPeriod'],
  data() {
    return {
      loading: false,
      totalRecoAmt: 0,
      totalCorrRecon: 0,
      totalAutoRecon: 0,
      totalManualRecon: 0,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      gstrThree2RowData: [],
      gstrThree2RowFields: [
        {
          key: 'selectBox',
          label: 'Select',
          stickyColumn: true,
          variant: 'info',
          class: 'stickyColumn col-sm-1'
        },
        { key: 'business_performance' },
        // { key: 'le_id', label: 'Legal Entity' },
        { key: 'fp_gstn', label: 'FP GSTN' },
        // { key: 'gl_prd', label: 'GL PRD' },
        // { key: 'gstr2b_prd', label: 'GSTR2B PRD' },
        // { key: 'gstr3b_period', label: 'GSTR3B Period' },
        { key: 'reconciled_amount', label: 'Reconciled Amount' },
        {
          key: 'igst',
          label: 'IGST',
          variant: 'success',
          class: 'text-center d-total-line'
        },

        {
          key: 'cgst',
          label: 'CGST',
          variant: 'success',
          class: 'text-center d-total-line'
        },
        {
          key: 'sgst',
          label: 'SGST',
          variant: 'success',
          class: 'text-center d-total-line'
        },
        { key: 'auto_recon', label: 'Auto Recon' },
        { key: 'correction_recon', label: 'Correction Recon' },
        { key: 'manaul_recon', label: 'Manual Recon' }
      ],
      fpGstn: null,
      period: null,
      legalEntity: null,
      selectAllCheckBox: false,
      selectedRow: [],
      showOpenGstr3BScr3Modal: false,
      minimizeFlag: true,
      rowDtlsScr2: null,
      searchFilterScr3: {
        leId: null,
        fpGstn: null,
        gstr3bPeriod: null
      },
      payload: null,
      unsubscribe: null,
      accessButtons: {
        deleteAllow: false,
        downloadAllow: true,
        uploadAllow: false,
        editAllow: false,
        addAllow: false,
        printAllow: false,
        approvalAllow: false
      }
    };
  },
  mounted() {
    if (this.rowDetails) {
      this.fpGstn = this.rowDetails.fp_gstn;
      this.legalEntity = this.rowDetails.le_id;
      this.searchFilterScr3 = {
        leId: this.rowDetails.le_id,
        fpGstn: this.rowDetails.fp_gstn
      };
    }
    if (this.rowPeriod) {
      this.period = this.rowPeriod;
      this.searchFilterScr3.gstr3bPeriod = this.rowPeriod;
    }
    if (this.rowDetails && this.rowPeriod) {
      this.getGstr3bScr2Data();
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'download' && !this.showOpenGstr3BScr3Modal) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = 1000;
          this.downloadExcel.downloadData(
            downloadpayload,
            'complainceDashboard/compTab3Scr2',
            'Business-Performance',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    getGstr3bScr2Data() {
      this.payload = {
        period: this.period,
        fp_Gst: this.fpGstn,
        le_id: this.legalEntity,
        _page: this.currentPage - 1,
        _limit: this.perPage
      };
      this.totalRecoAmt = 0;
      this.totalCorrRecon = 0;
      this.totalAutoRecon = 0;
      this.totalManualRecon = 0;
      this.loading = true;
      this.$store
        .dispatch('complainceDashboard/compTab3Scr2', this.payload)
        .then(response => {
          if (response.status === 200) {
            const result = response.data.data.page;
            this.gstrThree2RowData = result;
            for (let i of this.gstrThree2RowData) {
              this.totalRecoAmt = this.totalRecoAmt + i.reconciled_amount;
              this.totalCorrRecon = this.totalCorrRecon + i.correction_recon;
              this.totalAutoRecon = this.totalAutoRecon + i.auto_recon;
              this.totalManualRecon = this.totalManualRecon + i.manaul_recon;
            }
            this.totalRows = response.data.data.total_elements;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    selectAllBoxChecked(flag) {
      this.selectedRow = [];
      if (flag) {
        this.gstrThree2RowData = this.gstrThree2RowData.map((data, index) => {
          data.selectBox = this.selectAllCheckBox;
          if (this.selectAllCheckBox) {
            this.selectBoxChecked(data.selectBox, index);
          }
          return data;
        });
        this.selectedRow = this.gstrThree2RowData;
        for (let i of this.selectedRow) {
          this.totalRecoAmt = this.totalRecoAmt + i.reconciled_amount;
          this.totalAutoRecon = this.totalAutoRecon + i.auto_recon;
          this.totalCorrRecon = this.totalCorrRecon + i.correction_recon;
          this.totalManualRecon = this.totalManualRecon + i.manaul_recon;
        }
      } else {
        this.gstrThree2RowData = this.gstrThree2RowData.map((data, index) => {
          data.selectBox = this.selectAllCheckBox;
          if (this.selectAllCheckBox) {
            this.selectBoxChecked(data.selectBox, index);
          }
          return data;
        });
        this.selectedRow = [];
        this.totalRecoAmt = 0;
        this.totalCorrRecon = 0;
        this.totalAutoRecon = 0;
        this.totalManualRecon = 0;
        for (let i of this.gstrThree2RowData) {
          this.totalRecoAmt = this.totalRecoAmt + i.reconciled_amount;
          this.totalAutoRecon = this.totalAutoRecon + i.auto_recon;
          this.totalCorrRecon = this.totalCorrRecon + i.correction_recon;
          this.totalManualRecon = this.totalManualRecon + i.manaul_recon;
        }
      }
    },
    selectBoxChecked(flag, index, item, reconciledAmount) {
      this.totalRecoAmt = 0;
      this.totalCorrRecon = 0;
      this.totalAutoRecon = 0;
      this.totalManualRecon = 0;
      this.gstrThree2RowData[index].selectBox = flag;
      if (flag === true) {
        this.selectedRow.push(item);
      } else {
        this.selectedRow = this.selectedRow.filter(
          el => el.reconciled_amount !== reconciledAmount
        );
      }
    },
    showHideGstr3BScr3Modal(flag) {
      this.showOpenGstr3BScr3Modal = flag;
      this.$store.commit('complainceDashboard/setscr2Modal2Flag', false);
    },
    rowSelected(item) {
      this.showOpenGstr3BScr3Modal = true;
      this.rowDtlsScr2 = item;
      this.$store.commit('complainceDashboard/setscr2Modal2Flag', true);
    },
    maximize() {
      this.minimizeFlag = !this.minimizeFlag;
    },
    showTotal() {
      let totRecAmt = 0;
      let totAutoReco = 0;
      let totCorrReco = 0;
      let totManReco = 0;
      for (let i of this.selectedRow) {
        totRecAmt = totRecAmt + i.reconciled_amount;
        totAutoReco = totAutoReco + i.auto_recon;
        totCorrReco = totCorrReco + i.correction_recon;
        totManReco = totManReco + i.manaul_recon;
      }
      this.totalRecoAmt = totRecAmt;
      this.totalCorrRecon = totCorrReco;
      this.totalAutoRecon = totAutoReco;
      this.totalManualRecon = totManReco;
    },
    formattedNumber(num) {
      return commonHelper.formatCurrency(num);
    },
    expandColumn(data, class1, class2) {
      let elem = document.querySelectorAll(`.${class1}`);
      if (elem && elem[0] && elem[0].classList.contains(class1)) {
        if (data === 'reconciled_amount') {
          elem.forEach(el => {
            el.classList.replace('d-total-line', class2);
          });
        }
      } else {
        if (data === 'reconciled_amount') {
          let otherElem = document.querySelectorAll('.other-total-line');
          otherElem.forEach(el => {
            el.classList.replace('other-total-line', 'd-total-line');
          });
        }
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
