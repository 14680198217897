import appStrings from '@/app/utility/string.utility';
import commonHelper from '@/app/utility/common.helper.utility';
import gstrTwoBDetailsNearMatch from './gstrTwoBDetailsNearMatch';
export default {
  name: 'gstrTwobDetails',
  props: ['rowGstrDetails', 'searchGstrFilters', 'recoStatusFlag'],
  watch: {
    currentPage: function() {
      this.getGstrData() ? this.getGstrData() : this.getFlagDetails();
    },
    perPage: function() {
      this.rowGstrDetails.vendor_name = this.supplierName;
      this.rowGstrDetails.vendor_pan = this.vendorPan;
      this.currentPage = 1;
      this.getGstrData() ? this.getGstrData() : this.getFlagDetails();
    }
  },
  components: {
    gstrTwoBDetailsNearMatch
  },
  data() {
    return {
      selectAllCheckBox: false,
      showHistoryBtn: false,
      taxableValue: 0,
      igstAmount: 0,
      cgstAmount: 0,
      sgstAmount: 0,
      selectedRow: [],
      loading: false,
      currentPage: 1,
      showGstrNearMatch: false,
      totalRows: null,
      perPage: commonHelper.perPageData,
      pageOptions: commonHelper.getPageOptionsList(),
      leId: null,
      rowDetails: null,
      minimizeFlag: true,
      defaultValue: {
        value: null,
        text: null
      },
      leName: {
        value: null,
        text: null
      },
      selectedGSTNumber: null,
      selectedPeriodFrom: {
        value: null,
        text: null
      },
      selectedPeriodTo: {
        value: null,
        text: null
      },
      recoStatus: {
        value: null,
        text: null
      },
      selectedNearMatch: {
        value: 'N',
        text: 'No'
      },
      vendorPan: null,
      supplierName: null,
      tpGstn: null,
      invNum: null,
      gstrTwoDetailsData: [],
      gstrTwoDetailsFields: [
        {
          key: 'selectBox',
          label: 'Select',
          stickyColumn: true,
          variant: 'info',
          class: 'stickyColumn'
        },
        {
          key: 'fp_gstn',
          label: 'FP GSTN',
          variant: 'success',
          class: 'col-fix'
        },
        {
          key: 'vendor_gstn',
          label: 'Vendor GSTN',
          class: 'col-fix'
        },
        {
          key: 'supplier_name',
          label: 'Vendor Name'
        },
        {
          key: 'vendor_pan',
          label: 'Vendor PAN',
          class: 'col-fix'
        },
        {
          key: 'invoice_number',
          class: 'col-fix'
        },
        {
          key: 'invoice_date',
          class: 'col-fix'
        },
        {
          key: 'taxable_value',
          class: 'col-fix'
        },
        {
          key: 'rate',
          class: 'col-fix'
        },
        {
          key: 'igst_amount',
          class: 'col-fix'
        },
        {
          key: 'cgst_amount',
          class: 'col-fix'
        },
        {
          key: 'sgst_amount',
          class: 'col-fix'
        },
        {
          key: 'invoice_type',
          class: 'col-fix'
        }
      ],
      showValueSetModal: false,
      downloadPayload: null
    };
  },
  mounted() {
    if (this.searchGstrFilters) {
      this.getParentToChildData(this.searchGstrFilters);
    }
    this.getGstrData();
  },
  methods: {
    downloadExcelData() {
      this.loader = true;
      const downloadpayload = { ...this.downloadPayload };
      downloadpayload._limit = this.totalRows;
      if (this.selectedNearMatch.value) {
        this.downloadExcel.downloadData(
          downloadpayload,
          'complainceDashboard/getGstr2BDetailsFlag',
          'gstr-twoB-details-data',
          () => (this.loader = false)
        );
      } else {
        this.downloadExcel.downloadData(
          downloadpayload,
          'complainceDashboard/getGstrTwoBDetailsData',
          'gstr-twoB-details-data',
          () => (this.loader = false)
        );
      }     
    },
    formattedNumber(num) {
      return commonHelper.formatCurrency(num);
    },
    selectAllBoxChecked(flag) {
      this.selectedRow = [];
      if (flag) {
        this.gstrTwoDetailsData = this.gstrTwoDetailsData.map((data, index) => {
          data.selectBox = this.selectAllCheckBox;
          if (this.selectAllCheckBox) {
            this.selectBoxChecked(data.selectBox, index);
          }
          return data;
        });
        this.selectedRow = this.gstrTwoDetailsData;
        for (let i of this.selectedRow) {
          this.taxableValue = this.taxableValue + i.taxable_value;
          this.igstAmount = this.igstAmount + i.igst_amount;
          this.cgstAmount = this.cgstAmount + i.cgst_amount;
          this.sgstAmount = this.sgstAmount + i.sgst_amount;
        }
      } else {
        this.gstrTwoDetailsData = this.gstrTwoDetailsData.map((data, index) => {
          data.selectBox = this.selectAllCheckBox;
          if (this.selectAllCheckBox) {
            this.selectBoxChecked(data.selectBox, index);
          }
          return data;
        });
        this.selectedRow = [];
        this.taxablevalue = 0;
        this.igstAmount = 0;
        this.cgstAmount = 0;
        this.sgstAmount = 0;
        for (let i of this.gstrTwoDetailsData) {
          this.taxableValue = this.taxableValue + i.taxable_value;
          this.igstAmount = this.igstAmount + i.igst_amount;
          this.cgstAmount = this.cgstAmount + i.cgst_amount;
          this.sgstAmount = this.sgstAmount + i.sgst_amount;
        }
        this.showHistoryBtn = false;
      }
    },
    selectBoxChecked(flag, index, item, tax) {
      this.taxableValue = 0;
      this.igstAmount = 0;
      this.cgstAmount = 0;
      this.sgstAmount = 0;
      this.gstrTwoDetailsData[index].selectBox = flag;
      if (flag === true) {
        this.selectedRow.push(item);
      } else {
        this.selectedRow = this.selectedRow.filter(
          el => el.taxable_value !== tax
        );
      }
      if (this.selectedRow.length > 0) {
        this.showHistoryBtn = true;
      } else {
        this.showHistoryBtn = false;
      }
    },
    showHideGstrNearMatch(flag) {
      this.showGstrNearMatch = flag;
    },
    rowSelected(item) {
      this.rowDetails = item;
      this.leId = this.leName.value;
      this.showGstrNearMatch = true;
    },
    showTotal() {
      let taxVal = 0;
      let igst = 0;
      let cgst = 0;
      let sgst = 0;
      for (let i of this.selectedRow) {
        taxVal = taxVal + i.taxable_value;
        igst = igst + i.igst_amount;
        cgst = cgst + i.cgst_amount;
        sgst = sgst + i.sgst_amount;
      }
      this.taxableValue = taxVal;
      this.igstAmount = igst;
      this.cgstAmount = cgst;
      this.sgstAmount = sgst;
    },
    getParentToChildData(item) {
      this.leName.value = item.le_id;
      this.leName.text = item.leName;
      this.selectedPeriodFrom.text = item.period_start_date;
      this.selectedPeriodTo.text = item.period_end_date;
      this.selectedPeriodFrom.value = item.period_start_date;
      this.selectedPeriodTo.value = item.period_end_date;
      this.selectedGSTNumber = this.rowGstrDetails.gstn_no;
      this.vendorPan = this.rowGstrDetails.vendor_pan;
      this.supplierName = this.rowGstrDetails.supplier_no;
      this.recoStatus = {
        value: this.recoStatusFlag.value,
        text: this.recoStatusFlag.text
      };
    },
    getGstrData() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        le_id: this.searchGstrFilters.le_id,
        fp_gstn: this.rowGstrDetails.gstn_no,
        period_from: this.searchGstrFilters.period_start_date,
        period_to: this.searchGstrFilters.period_end_date,
        vendor_pan: this.rowGstrDetails.vendor_pan
          ? this.rowGstrDetails.vendor_pan
          : null,
        supplier_Name: this.supplierName ? this.supplierName : null,
        invoice_num: this.invoice,
        tp_gstn: this.tpGstn,
        status: this.recoStatusFlag.value,
        flag: 'N'
      };
      this.downloadPayload = payload;
      this.loading = true;
      this.$store
        .dispatch('complainceDashboard/getGstr2BDetailsFlag', payload)
        .then(response => {
          this.loading = false;
          if (response.status === 200) {
            this.gstrTwoDetailsData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
            for (let i of this.gstrTwoDetailsData) {
              this.taxableValue = this.taxableValue + i.taxable_value;
              this.igstAmount = this.igstAmount + i.igst_amount;
              this.cgstAmount = this.cgstAmount + i.cgst_amount;
              this.sgstAmount = this.sgstAmount + i.sgst_amount;
            }
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getGstrTwoBDetailsData() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        le_id: this.leName.value,
        fp_gstn: this.selectedGSTNumber,
        period_from: this.selectedPeriodFrom.value,
        period_to: this.selectedPeriodTo.value,
        vendor_pan: this.vendorPan ? this.vendorPan : null,
        supplier_Name: this.supplierName ? this.supplierName : null,
        invoice_num: this.invNum,
        tp_gstn: this.tpGstn,
        status: this.recoStatus.value
      };
      this.downloadPayload = payload;
      this.loading = true;
      this.taxableValue = 0;
      this.igstAmount = 0;
      this.cgstAmount = 0;
      this.sgstAmount = 0;
      this.$store
        .dispatch('complainceDashboard/getGstrData', payload)
        .then(response => {
          this.loading = false;
          if (response.status === 200) {
            this.gstrTwoDetailsData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
            for (let i of this.gstrTwoDetailsData) {
              this.taxableValue = this.taxableValue + i.taxable_value;
              this.igstAmount = this.igstAmount + i.igst_amount;
              this.cgstAmount = this.cgstAmount + i.cgst_amount;
              this.sgstAmount = this.sgstAmount + i.sgst_amount;
            }
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getGstrTwoBDetailsFlag() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        le_id: this.leName.value,
        fp_gstn: this.selectedGSTNumber,
        period_from: this.selectedPeriodFrom.value,
        period_to: this.selectedPeriodTo.value,
        vendor_pan: this.vendorPan ? this.vendorPan : null,
        supplier_Name: this.supplierName ? this.supplierName : null,
        invoice_num: this.invNum,
        tp_gstn: this.tpGstn,
        status: this.recoStatus.value,
        flag: this.selectedNearMatch.value
      };
      this.loading = true;
      this.taxableValue = 0;
      this.igstAmount = 0;
      this.cgstAmount = 0;
      this.sgstAmount = 0;
      this.$store
        .dispatch('complainceDashboard/getGstr2BDetailsFlag', payload)
        .then(response => {
          this.loading = false;
          if (response.status === 200) {
            this.gstrTwoDetailsData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
            for (let i of this.gstrTwoDetailsData) {
              this.taxableValue = this.taxableValue + i.taxable_value;
              this.igstAmount = this.igstAmount + i.igst_amount;
              this.cgstAmount = this.cgstAmount + i.cgst_amount;
              this.sgstAmount = this.sgstAmount + i.sgst_amount;
            }
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getFlagDetails() {
      if (this.selectedNearMatch.value) {
        this.getGstrTwoBDetailsFlag();
      } else {
        this.getGstrTwoBDetailsData();
      }
    },
    openValueSetModal(vsetCode, periodType) {
      this.showValueSetModal = true;
      this.vsetCode = vsetCode;
      this.periodType = periodType;
      this.parent_value_set_id = null;
      if (
        this.vsetCode === appStrings.VALUESETTYPE.GST_FIRST_PARTY_GSTN ||
        this.vsetCode === appStrings.VALUESETTYPE.GL_PERIOD
      ) {
        this.parent_value_set_id = this.leName.value;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.leName = {
          value: item.org_id,
          text: item.org_name
        };
        this.selectedGSTNumber = null;
        this.selectedPeriodFrom = this.defaultValue;
        this.selectedPeriodTo = this.defaultValue;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.GST_FIRST_PARTY_GSTN
      ) {
        this.selectedGSTNumber = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.GL_PERIOD) {
        if (this.periodType === 'periodFrom') {
          this.selectedPeriodFrom = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
        } else if (this.periodType === 'periodTo') {
          this.selectedPeriodTo = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
        }
      } else if (this.vsetCode === appStrings.VALUESETTYPE.GST_URP) {
        this.recoStatus = {
          value: item.value_code,
          text: item.value_meaning
        };
        // this.showValueSetModal = false;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.YES_NO) {
        this.selectedNearMatch = {
          value: item.value_code,
          text: item.value_meaning
        };
        if (this.selectedNearMatch.value === 'Y') {
          this.recoStatus = {
            value: 'U',
            text: 'UnReconciled'
          };
        } else {
          this.recoStatus = this.defaultValue;
        }
      }
      this.showValueSetModal = false;
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clear() {
      this.leName = this.defaultValue;
      this.selectedGSTNumber = null;
      this.selectedPeriodFrom = this.defaultValue;
      this.selectedPeriodTo = this.defaultValue;
      this.vendorPan = null;
      this.supplierName = null;
      this.tpGstn = null;
      this.invNum = null;
      this.totalRows = null;
      this.searchParams = null;
      this.requestParams = {};
    },
    clearVsetValues(vsetCode, periodType) {
      if (vsetCode === this.leName.value) {
        this.leName = this.defaultValue;
        this.selectedGSTNumber = null;
        this.selectedPeriodFrom = this.defaultValue;
        this.selectedPeriodTo = this.defaultValue;
      } else if (vsetCode === this.selectedGSTNumber.value) {
        this.selectedGSTNumber = null;
      } else if (vsetCode === this.selectedNearMatch.text) {
        this.selectedNearMatch.text = null;
        this.selectedNearMatch.value = null;
      } else if (vsetCode === appStrings.VALUESETTYPE.GL_PERIOD) {
        if (periodType === 'periodFrom') {
          this.selectedPeriodFrom = this.defaultValue;
        } else if (periodType === 'periodTo') {
          this.selectedPeriodTo = this.defaultValue;
        }
      }
      //  else if (vsetCode === this.recoGst.value) {
      //   this.recoGst = this.defaultValue;
      // }
      else if (vsetCode === this.recoStatus.value) {
        this.recoStatus = this.defaultValue;
      }
      // this.vendorPan = null;
      // this.supplierName = null;
      // this.tpGstn = null;
      // this.invNum = null;
    },
    maximize() {
      this.minimizeFlag = !this.minimizeFlag;
    }
  },
  beforeDestroy() {
    // this.unsubscribe();
  }
};
