import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'gstrThreeBScr2',
  watch: {
    currentPage: function() {
      this.getGstr3bScr4Data();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getGstr3bScr4Data();
    }
  },
  components: {},
  props: ['rowDtlsScr3', 'searchFilterScr3'],
  data() {
    return {
      loading: false,
      totalRecoAmt: 0,
      totalCorrRecon: 0,
      totalAutoRecon: 0,
      totalManualRecon: 0,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      gstr3BScr4Data: [],
      gstr3BScr4Fields: [
        {
          key: 'selectBox',
          label: 'Select',
          stickyColumn: true,
          variant: 'info',
          class: 'stickyColumn col-sm-1'
        },
        { key: 'business_performance' },
        { key: 'vendor_name', label: 'Vendor Name' },
        { key: 'vendor_pan', label: 'Vendor Pan' },
        { key: 'reconciled_amount', label: 'Reconciled Amount' },
        // {
        //   key: 'igst',
        //   label: 'IGST',
        //   variant: 'success',
        //   class: 'text-center d-total-line'
        // },

        // {
        //   key: 'cgst',
        //   label: 'CGST',
        //   variant: 'success',
        //   class: 'text-center d-total-line'
        // },
        // {
        //   key: 'sgst',
        //   label: 'SGST',
        //   variant: 'success',
        //   class: 'text-center d-total-line'
        // },
        // { key: 'auto_recon', label: 'Auto Recon' },
        { key: 'correction_recon', label: 'Correction Recon' },
        { key: 'manaul_recon', label: 'Manual Recon' }
      ],
      selectAllCheckBox: false,
      selectedRow: [],
      vendorName: null,
      vendorPan: null,
      showValueSetModal: false,
      payload: null,
      unsubscribe: null
    };
  },
  mounted() {
    if (this.rowDtlsScr3 && this.searchFilterScr3) {
      this.getGstr3bScr4Data();
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'download') {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = 1000;
          this.downloadExcel.downloadData(
            downloadpayload,
            'complainceDashboard/compTab3Scr4',
            'Business-Performance-Vendor-Wise',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    getGstr3bScr4Data() {
      this.payload = {
        gstr3b_period: this.searchFilterScr3.gstr3bPeriod,
        Fp_Gst: this.searchFilterScr3.fpGstn,
        le_id: this.searchFilterScr3.leId,
        gstr2b_prd: this.rowDtlsScr3.gstr2b_prd,
        business_performance: this.rowDtlsScr3.business_performance,
        vendor_name: this.vendorName,
        vendor_pan: this.vendorPan,
        _page: this.currentPage - 1,
        _limit: this.perPage
      };
      this.totalRecoAmt = 0;
      this.totalCorrRecon = 0;
      this.totalAutoRecon = 0;
      this.totalManualRecon = 0;
      this.loading = true;
      this.$store
        .dispatch('complainceDashboard/compTab3Scr4', this.payload)
        .then(response => {
          if (response.status === 200) {
            const result = response.data.data.page;
            this.gstr3BScr4Data = result;
            for (let i of this.gstr3BScr4Data) {
              this.totalRecoAmt = this.totalRecoAmt + i.reconciled_amount;
              this.totalCorrRecon = this.totalCorrRecon + i.correction_recon;
              this.totalAutoRecon = this.totalAutoRecon + i.auto_recon;
              this.totalManualRecon = this.totalManualRecon + i.manaul_recon;
            }
            this.totalRows = response.data.data.total_elements;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    selectAllBoxChecked(flag) {
      this.selectedRow = [];
      if (flag) {
        this.gstr3BScr4Data = this.gstr3BScr4Data.map((data, index) => {
          data.selectBox = this.selectAllCheckBox;
          if (this.selectAllCheckBox) {
            this.selectBoxChecked(data.selectBox, index);
          }
          return data;
        });
        this.selectedRow = this.gstr3BScr4Data;
        for (let i of this.selectedRow) {
          this.totalRecoAmt = this.totalRecoAmt + i.reconciled_amount;
          this.totalAutoRecon = this.totalAutoRecon + i.auto_recon;
          this.totalCorrRecon = this.totalCorrRecon + i.correction_recon;
          this.totalManualRecon = this.totalManualRecon + i.manaul_recon;
        }
      } else {
        this.gstr3BScr4Data = this.gstr3BScr4Data.map((data, index) => {
          data.selectBox = this.selectAllCheckBox;
          if (this.selectAllCheckBox) {
            this.selectBoxChecked(data.selectBox, index);
          }
          return data;
        });
        this.selectedRow = [];
        this.totalRecoAmt = 0;
        this.totalCorrRecon = 0;
        this.totalAutoRecon = 0;
        this.totalManualRecon = 0;
        for (let i of this.gstr3BScr4Data) {
          this.totalRecoAmt = this.totalRecoAmt + i.reconciled_amount;
          this.totalAutoRecon = this.totalAutoRecon + i.auto_recon;
          this.totalCorrRecon = this.totalCorrRecon + i.correction_recon;
          this.totalManualRecon = this.totalManualRecon + i.manaul_recon;
        }
      }
    },
    selectBoxChecked(flag, index, item, reconciledAmount) {
      this.totalRecoAmt = 0;
      this.totalCorrRecon = 0;
      this.totalAutoRecon = 0;
      this.totalManualRecon = 0;
      this.gstr3BScr4Data[index].selectBox = flag;
      if (flag === true) {
        this.selectedRow.push(item);
      } else {
        this.selectedRow = this.selectedRow.filter(
          el => el.reconciled_amount !== reconciledAmount
        );
      }
    },
    showHideGstr3BScr3Modal(flag) {
      this.showOpenGstr3BScr3Modal = flag;
    },
    rowSelected(item) {
      this.showOpenGstr3BScr3Modal = true;
      this.rowDtlsScr2 = item;
    },
    maximize() {
      this.minimizeFlag = !this.minimizeFlag;
    },
    showTotal() {
      let totRecAmt = 0;
      let totAutoReco = 0;
      let totCorrReco = 0;
      let totManReco = 0;
      for (let i of this.selectedRow) {
        totRecAmt = totRecAmt + i.reconciled_amount;
        totAutoReco = totAutoReco + i.auto_recon;
        totCorrReco = totCorrReco + i.correction_recon;
        totManReco = totManReco + i.manaul_recon;
      }
      this.totalRecoAmt = totRecAmt;
      this.totalCorrRecon = totCorrReco;
      this.totalAutoRecon = totAutoReco;
      this.totalManualRecon = totManReco;
    },
    formattedNumber(num) {
      return commonHelper.formatCurrency(num);
    },
    clear() {
      this.vendorName = null;
      this.vendorPan = null;
    }
    // expandColumn(data, class1, class2) {
    //   let elem = document.querySelectorAll(`.${class1}`);
    //   if (elem && elem[0] && elem[0].classList.contains(class1)) {
    //     if (data === 'reconciled_amount') {
    //       elem.forEach(el => {
    //         el.classList.replace('d-total-line', class2);
    //       });
    //     }
    //   } else {
    //     if (data === 'reconciled_amount') {
    //       let otherElem = document.querySelectorAll('.other-total-line');
    //       otherElem.forEach(el => {
    //         el.classList.replace('other-total-line', 'd-total-line');
    //       });
    //     }
    //   }
    // }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
