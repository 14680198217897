import gstrNearMatch from './gstrNearMatch';
import itcReconciliation from '../../../../../gstrTwoA/itcReconciliation';
export default {
  name: 'gstrDetailsNearMatch',
  components: { gstrNearMatch, itcReconciliation },
  props: [ 'rowDetails', 'leId', 'searchFilters', 'inv_num' ],
  data() {
    return {
      showHistoryBtn: false,
      unsubscribe: null,
      loader: false,
      currentPage: 1,
      totalRows: null,
      activeTab: 'gstrNearMatch',
      gstrDetailsData: [],
      gstrDetailsFields: [
        {
          key: 'fp_gstn',
          label: 'FP GSTN',
          variant: 'success',
          class: 'col-fix'
        },
        {
          key: 'vendor_name'
        },
        {
          key: 'vendor_pan',
          label: 'Vendor PAN',
          class: 'text-center col-fix'
        },
        {
          key: 'gl_date',
          label: 'GL Date',
          class: 'gldate col-fix'
        },
        {
          key: 'invoice_num',
          class: 'col-fix'
        },
        {
          key: 'rate',
          class: 'rate col-fix'
        },
        {
          key: 'vendor_gstn',
          label: 'Vendor GSTN',
          class: 'col-fix'
        },
        {
          key: 'taxable_amt',
          class: 'text-center col-fix'
        },
        {
          key: 'total_unrec_tax',
          label: 'Unreco Tax',
          class: 'text-center col-fix'
        },
        {
          key: 'igst',
          label: 'IGST',
          class: 'd-total-unre gst text-center col-fix',
          variant: 'success'
        },
        {
          key: 'csgst',
          label: 'CGST',
          class: 'd-total-unre gst text-center col-fix',
          variant: 'success'
        },
        {
          key: 'sgst',
          label: 'SGST',
          class: 'd-total-unre gst text-center col-fix',
          variant: 'success'
        },
        {
          key: 'total_rec_tax',
          label: 'Reco Tax',
          class: 'text-center col-fix'
        }
      ],
      invoice_num: null
    };
  },
  mounted() {
    this.invoice_num = this.inv_num;
    this.gstrDetailsData = [this.rowDetails];
  },
  methods: {
    totalRowsCount(item) {
      this.totalRows = item;
    },
    selectedTab(activeTab) {
      this.totalRows = 0;
      this.activeTab = activeTab;
      this.showHistoryBtn = false;
      if (activeTab === 'gstrNearMatch') {
        this.inprocessActive = true;
      } else {
        this.inprocessActive = false;
      }
    },
  }
};
